import { Box, HStack } from "@chakra-ui/react";
import React from "react";
import { ContextScope } from "../Context/ContextProvider";
import SingleChat from "./SingleChat";
import { useSelector } from "react-redux";
import SinglePrivateChat from "./SinglePrivateChat";
import MyPrivateChats from "./MyPrivateChats";

const PrivateChatBox = () => {
  const fetchAgain = useSelector((state) => state.fetchAgain.fetchAgain);
  const { selectedChat } = ContextScope();
  return (
    <HStack h={"100%"}>
      <MyPrivateChats />
      <Box
        display={{ base: selectedChat ? "flex" : "none", md: "flex" }}
        alignItems="center"
        flexDir="column"
        p={3}
        width={"100%"}
        height={"90%"}
        borderRadius="lg"
      >
        <SinglePrivateChat />
      </Box>
    </HStack>
  );
};

export default PrivateChatBox;
