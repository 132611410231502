import {
  Box,
  Button,
  Collapse,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { setIsEventModalOpen } from "../slices/isEventModalOpenSlice";
import DatePicker from "react-datepicker";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { mainPath } from "../../config/AxiosConfig";
import { setFetchAgain } from "../slices/fetchAgainSlice";
import axios from "axios";
import EventsComponent from "./EventsComponent";
import { format } from "date-fns";

const EventsModal = ({ user }) => {
  const selectedTask = useSelector((state) => state.selectedTask.selectedTask);
  const today = new Date();
  //   const date = new Date(format(today, "yyyy/MM/dd"));
  const [date, setDate] = useState(new Date());
  const [content, setContent] = useState(<Spinner color="blue.200" />);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const isEventModalOpen = useSelector(
    (state) => state.isEventModalOpen.isEventModalOpen
  );
  const dateHandle = (where) => {
    const current = new Date(format(date, "yyyy/MM/dd"));
    if (where === "plus") {
      setDate(current.setDate(current.getDate() + 1));
    } else {
      setDate(current.setDate(current.getDate() - 1));
    }
  };

  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(setIsEventModalOpen(false));
  };
  useEffect(() => {
    setContent(
      <EventsComponent
        cid={selectedTask?._id}
        user={user}
        date={format(new Date(date), "yyyy-MM-dd")}
      />
    );
  }, [date]);
  useEffect(() => {
    setContent(
      <EventsComponent
        cid={selectedTask?._id}
        user={user}
        date={format(new Date(date), "yyyy-MM-dd")}
      />
    );
  }, [selectedTask]);
  return (
    <Modal size={"md"} isOpen={isEventModalOpen} onClose={closeModal}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader p={"10px 5px 40px 5px"}>
          <ModalCloseButton marginTop={"5px"} bg={"#efefef"} />
        </ModalHeader>
        <ModalBody>
          <p className="small gray">{selectedTask?.chatName}</p>
          <HStack marginTop={"10px"} w={"100%"}>
            <Button
              bg={"blue.200"}
              w={"100%"}
              onClick={() => {
                dateHandle("minus");
              }}
            >
              {" "}
              <FontAwesomeIcon color="white" icon={faAngleLeft} />
            </Button>

            <DatePicker
              wrapperClassName="datepicker"
              alignItems={"center"}
              width="150px"
              textAlign={"center"}
              selected={date}
            />
            <Button
              bg={"blue.200"}
              w={"100%"}
              onClick={() => {
                dateHandle("plus");
              }}
            >
              <FontAwesomeIcon color="white" icon={faAngleRight} />
            </Button>
          </HStack>
          {content}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EventsModal;
