import { createSlice } from "@reduxjs/toolkit";

export const newMessageIdSlice = createSlice({
  name: "newMessageId",
  initialState: {},
  reducers: {
    setNewMessageId: (state, action) => {
      state.newMessageId = action.payload;
    },
  },
});

export const { setNewMessageId } = newMessageIdSlice.actions;
export default newMessageIdSlice.reducer;
