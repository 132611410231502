import { ContextScope } from "../../Context/ContextProvider";
import { setFetchAgain } from "../slices/fetchAgainSlice";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useDisclosure,
  Button,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { mainPath } from "../../config/AxiosConfig";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const DelNote = ({ chat }) => {
  const dispatch = useDispatch();
  const fetchAgain = useSelector((state) => state.fetchAgain.fetchAgain);
  const user = useSelector((state) => state.userInfo.userInfo);

  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();
  const handleDel = () => {
    onClose();
    deleteNote();
    setLoading(true);
    //    handleRemoveUser(user)
  };
  const deleteNote = async () => {
    if (chat?.sender?._id !== user._id) {
      toast({
        title: `You're not allowed to remove users`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-left",
      });
      return;
    }
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await axios.put(
        `${mainPath}/api/note/delete`,
        {
          noteId: chat._id,
        },
        config,
      );
      // console.log(data);
      if (data) {
        dispatch(setFetchAgain(!fetchAgain));
        setLoading(false);
      }
    } catch (error) {
      // console.log(error);
      toast({
        title: `here: ${error.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-left",
      });
      //   setRenameLoading(false);
    }
  };
  return (
    <>
      <Button isLoading={loading} bg="transparent" onClick={onOpen}>
        <FontAwesomeIcon className="hand" color="red" icon={faXmark} />
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Usuń notatkę
            </AlertDialogHeader>

            <AlertDialogBody>
              Czy usunąć notatkę? Tego działania nie można cofnąć.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                anuluj
              </Button>
              <Button colorScheme="red" onClick={handleDel} ml={3}>
                Usuń
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default DelNote;
