import { createSlice } from "@reduxjs/toolkit";

export const chatFilesSlice = createSlice({
  name: "chatFiles",
  initialState: {
    chatFiles: {},
  },

  reducers: {
    setChatFiles: (state, action) => {
      state.chatFiles = action.payload;
    },
  },
});

export const { setChatFiles } = chatFilesSlice.actions;

export default chatFilesSlice.reducer;
