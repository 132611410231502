import { Button, HStack, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { mainPath } from "../../config/AxiosConfig";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedTask } from "../slices/selectedTaskSlice";

const SubtasksButtonComponent = ({
  user,
  chat,
  onToggle,
  setSelectedChat,
  subtasks,
  setSubtasks,
}) => {
  const [up, setUp] = useState(false);
  const [news, setNews] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newMessages, setNewMessages] = useState("transparent");
  const fetchMessagesAgain = useSelector(
    (state) => state.fetchMessagesAgain.fetchMessagesAgain
  );
  const newMessageId = useSelector((state) => state.newMessageId.newMessageId);
  const dispatch = useDispatch();
  const getSubtasks = async () => {
    setNews(false);
    setLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await axios
        .post(
          `${mainPath}/api/chat/subtasks/${chat?._id}`,
          {
            chatId: chat?._id,
            userId: user?._id,
          },
          config
        )
        .then((res) => {
          setSubtasks(res.data);
          setLoading(false);
          // // console.log(`s.newMessages: `);
          // // console.log(res.data);
          res.data?.map((s) => {
            if (s.newMessages > 0 || s.newPrivateMessages > 0) setNews(true);
          });
        });
    } catch (error) {
      // // // // console.log(error);
      // toast({
      //   title: error.message,
      //   status: "error",
      //   duration: 5000,
      //   isClosable: true,
      //   position: "bottom-left",
      // });
      setLoading(false);
    }
  };
  const setList = () => {
    // setSelectedChat(chat);
    getSubtasks();
    onToggle();
  };
  const closeList = () => {
    // setSelectedChat(chat);
    getSubtasks();
    // setSubtasks(subtasks);
    onToggle();
  };
  useEffect(() => {
    if (chat._id) getSubtasks();
  }, [chat]);
  useEffect(() => {
    // // console.log(`newMessageIdgetSubtasks`);
    // // console.log(chat._id);
    // // console.log(newMessageId?.newMessageParentId);
    // // console.log(newMessageId);
    if (
      chat._id === newMessageId?.newMessageId ||
      chat._id === newMessageId?.newMessageParentId
    ) {
      getSubtasks();
      // // console.log(`newMessageIdgetSubtasksOK`);
    }
  }, [fetchMessagesAgain]);
  return (
    <>
      {up === false ? (
        <Button
          isDisabled={loading}
          bg={news ? "red.100" : chat.done ? "transparent" : "transparent"}
          color={chat.done ? "#9fba3c" : "blue.300"}
          border={chat.done ? "2px solid #9fba3c" : "none"}
          p={"1px 1px 1px 10px"}
          size={"xs"}
          onClick={() => {
            dispatch(setSelectedTask(chat));
            setUp(true);
            setList();
          }}
          leftIcon={<FontAwesomeIcon icon={faChevronDown} />}
          rightIcon={
            <FontAwesomeIcon size={"xs"} color={newMessages} icon={faCircle} />
          }
        >
          <HStack>
            {" "}
            (
            <span className="small" color="gray.100">
              {subtasks.length}
            </span>
            )
          </HStack>
        </Button>
      ) : (
        <Button
          color={chat.done ? "#9fba3c" : "blue.300"}
          p={"1px 1px 1px 10px"}
          size={"xs"}
          onClick={() => {
            setUp(false);
            closeList();
          }}
          bg={news ? "red.100" : chat.done ? "transparent" : "transparent"}
          border={chat.done ? "2px solid #9fba3c" : "none"}
          leftIcon={<FontAwesomeIcon icon={faChevronUp} />}
          rightIcon={
            <FontAwesomeIcon size="xs" color={newMessages} icon={faCircle} />
          }
        >
          <HStack>
            {" "}
            (
            <span className="small" color="gray.100">
              {subtasks.length}
            </span>
            )
          </HStack>
        </Button>
      )}
    </>
  );
};

export default SubtasksButtonComponent;
