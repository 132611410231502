import { createSlice } from "@reduxjs/toolkit";

export const isEventModalOpenSlice = createSlice({
  name: "isEventModalOpen",
  initialState: {
    isEventModalOpen: false,
  },
  reducers: {
    setIsEventModalOpen: (state, action) => {
      state.isEventModalOpen = action.payload;
    },
  },
});

export const { setIsEventModalOpen } = isEventModalOpenSlice.actions;
export default isEventModalOpenSlice.reducer;
