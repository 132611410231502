import { Avatar, Box, Button, HStack, Tooltip } from "@chakra-ui/react";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-regular-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import ShowAFileComponent from "./ShowAFileComponent";
const MessageComponent = ({
  isLastMessage,
  isSameSender,
  isSameSenderMargin,
  isSameUser,
  messages,
  m,
  i,
  user,
}) => {
  return (
    <HStack className="messagesWrap" style={{ display: "flex" }} key={m._id}>
      {(isSameSender(messages, m, i, user._id) ||
        isLastMessage(messages, i, user._id)) && (
        <Avatar
          mt="7px"
          mr={1}
          size="sm"
          cursor="pointer"
          name={m.sender.name}
          src={m.sender.pic}
        />
      )}
      <span
        className="small"
        style={{
          // marginLeft: `${
          //     m.sender._id === user._id ? 33 : 0
          // }`,
          marginLeft: isSameSenderMargin(messages, m, i, user._id),
          backgroundColor: `${
            m.sender._id === user._id ? "#e4eff5" : "#98d1eb"
          }`,
          marginTop: "1px",
          borderRadius: "6px",
          padding: "9px 15px",
          maxWidth: "75%",
        }}
      >
        {m.sender._id !== user._id ? (
          <p className="small gray">
            {m.sender.name}:{" "}
            {format(new Date(m.createdAt.toString()), "yyyy-MM-dd")}
          </p>
        ) : (
          <></>
        )}
        {m.content}
      </span>
      {m.file && (
        <ShowAFileComponent
          user={user}
          cid={m.chat._id}
          mid={m._id}
          caption={m.fileCaption}
        />
      )}
    </HStack>
  );
};

export default MessageComponent;
