import { createSlice } from "@reduxjs/toolkit";

export const isChatDrawerOpenSlice = createSlice({
  name: "isChatDrawerOpen",
  initialState: {
    isChatDrawerOpen: false,
  },
  reducers: {
    setIsChatDrawerOpen: (state, action) => {
      state.isChatDrawerOpen = action.payload;
    },
  },
});

export const { setIsChatDrawerOpen } = isChatDrawerOpenSlice.actions;
export default isChatDrawerOpenSlice.reducer;
