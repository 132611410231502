import { Box, Button, HStack } from "@chakra-ui/react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { mainPath } from "../../config/AxiosConfig";
import { setFetchAgain } from "../slices/fetchAgainSlice";

const Event = ({ event, index }) => {
  const dispatch = useDispatch();
  const fetchAgain = useSelector((state) => state.fetchAgain.fetchAgain);
  const user = useSelector((state) => state.userInfo.userInfo);
  const [delLoading, setDelLoading] = useState(false);
  const handleDel = async () => {
    setDelLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await axios
        .post(`${mainPath}/api/event/del/${event._id}`, {
          eid: event._id,
          uid: user._id,
        })
        .then((res) => {
          console.log(res);
          setDelLoading(false);
          dispatch(setFetchAgain(!fetchAgain));
        }, config);
    } catch (error) {
      console.log(error.data);
      setDelLoading(false);
    }
  };
  return (
    <>
      <Box
        alignSelf={"left"}
        textAlign={"left"}
        alignContent={"left"}
        w={"100%"}
      >
        {event ? (
          <HStack className="small" w={"100%"}>
            <Button
              isLoading={delLoading}
              bg={"transparent"}
              size={"xs"}
              onClick={handleDel}
            >
              <FontAwesomeIcon color="red" icon={faXmark} />
            </Button>
            <p>
              <span className="strong">
                {event.hour}:{event.minute}{" "}
              </span>
              {event.description}
            </p>
          </HStack>
        ) : (
          <p className="small lightgray">
            ---------------------------------------------------------
          </p>
        )}
      </Box>
    </>
  );
};

export default Event;
