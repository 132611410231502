import { Box, Button, HStack } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "@chakra-ui/react";
import { mainPath } from "../config/AxiosConfig";
import axios from "axios";
import { useState } from "react";
const ShowAFileComponent = ({ user, cid, mid, caption }) => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  const [loading, setLoading] = useState(false);
  const getAFile = async () => {
    setLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await axios
        .post(
          // `${mainPath}/api/chat/sort/${sort}/${asc}`,
          `${mainPath}/api/file/get`,
          {
            uid: user._id,
            cid: cid,
            mid: mid,
          },
          config
        )
        .then((res) => {
          console.log(res);
          openInNewTab(res.data.url);
        });
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <Box p={1} borderRadius={3}>
      <HStack>
        <Button
          borderRadius={"full"}
          onClick={getAFile}
          bg={"white"}
          color={"blue.400"}
          size={"xs"}
        >
          <Tooltip label={caption} fontSize="md">
            <FontAwesomeIcon size="md" icon={faPaperclip} />
          </Tooltip>
        </Button>
      </HStack>
    </Box>
  );
};

export default ShowAFileComponent;
