import { createSlice } from "@reduxjs/toolkit";

export const selectedTaskSlice = createSlice({
  name: "selectedTask",
  initialState: {
    selectedTask: {},
  },

  reducers: {
    setSelectedTask: (state, action) => {
      state.selectedTask = action.payload;
    },
  },
});

export const { setSelectedTask } = selectedTaskSlice.actions;

export default selectedTaskSlice.reducer;
