import {
  Box,
  Button,
  FormControl,
  HStack,
  IconButton,
  Input,
  Spinner,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import SearchUser from "../users/SearchUser";
import DatePicker from "react-datepicker";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { SettingsIcon } from "@chakra-ui/icons";
import { ContextScope } from "../../Context/ContextProvider";
import UserBadgeItem from "../UserAvatar/UserBadgeItem";
import axios from "axios";
import { mainPath } from "../../config/AxiosConfig";
import UserListItem from "../UserAvatar/UserListItem";
import DelTask from "../task/DelTask";
import { useDispatch, useSelector } from "react-redux";
import { setIsUpdateChatModalOpen } from "../slices/isUpdateChatModalOpenSlice";
import { setFetchAgain } from "../slices/fetchAgainSlice";
import { set } from "date-fns";
import { Editor } from "@tinymce/tinymce-react";
import { setSelectedTask } from "../slices/selectedTaskSlice";

const UpdateGroupChatModal = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSuper, setIsSuper] = useState(false);
  const fetchAgain = useSelector((state) => state.fetchAgain.fetchAgain);
  const isUpdateChatModalOpen = useSelector(
    (state) => state.isUpdateChatModalOpen.isUpdateChatModalOpen
  );
  const [groupChatName, setGroupChatName] = useState();
  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [groupSuper, setGroupSuper] = useState();
  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [superLoading, setSuperLoading] = useState(false);
  const [renameLoading, setRenameLoading] = useState(false);
  const [dateChangeLoading, setDateChangeLoading] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const [descriptionChangeLoading, setDescriptionChangeLoading] =
    useState(false);
  const user = useSelector((state) => state.userInfo.userInfo);
  const { selectedChat, setSelectedChat } = ContextScope();
  const { isOpen, onOpen, onClose } = useDisclosure();
  let content = <></>;
  const toast = useToast();
  const [description, setDescription] = useState(null);
  const [initialDescription, setInitialDescription] = useState(null);
  const dispatch = useDispatch();
  const modalMode = useSelector((state) => state.modalMode.modalMode);

  const sendInvitation = async ({ user, from, title }) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await axios
        .post(
          `${mainPath}/api/email/invitation`,
          {
            email: user.email,
            from: from,
            title: title,
          },
          config
        )
        .then((res) => {});
      // setChats([data, ...chats]);
      // onClose();
    } catch (error) {
      // console.log(error);
    }
  };

  const handleDateChange = async () => {
    setDateChangeLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await axios
        .put(
          `${mainPath}/api/chat/datechange`,
          {
            chatId: selectedChat?._id,
            startDate: startDate,
            newDate: endDate,
          },
          config
        )
        .then((res) => {
          setSelectedChat(res.data);
          dispatch(setSelectedTask(res.data));
          dispatch(setFetchAgain(!fetchAgain));
          setDateChangeLoading(false);
          // // console.log(`data`);
          // // console.log(res.data);
          // // console.log(`data`);
        });
    } catch (error) {
      // toast({
      //   title: error.message,
      //   status: "error",
      //   duration: 5000,
      //   isClosable: true,
      //   position: "top-left",
      // });
      setDateChangeLoading(false);
    }
  };
  const handleDescriptionChange = async () => {
    setDescriptionChangeLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await axios
        .put(
          `${mainPath}/api/chat/descriptionchange`,
          {
            chatId: selectedChat?._id,
            newDescription: description,
          },
          config
        )
        .then((res) => {
          setSelectedChat(res.data);
          dispatch(setSelectedTask(res.data));
          dispatch(setFetchAgain(!fetchAgain));
          setDescriptionChangeLoading(false);
          // // console.log(`data`);
          // // console.log(res.data);
          // // console.log(`data`);
        });
    } catch (error) {
      // toast({
      //   title: error.message,
      //   status: "error",
      //   duration: 5000,
      //   isClosable: true,
      //   position: "top-left",
      // });
      setDateChangeLoading(false);
    }
  };
  const handleRename = async () => {
    setRenameLoading(true);
    if (!groupChatName) return;
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await axios
        .put(
          `${mainPath}/api/chat/rename`,
          {
            chatId: selectedChat?._id,
            chatName: groupChatName,
          },
          config
        )
        .then((res) => {
          setSelectedChat(res.data);
          dispatch(setSelectedTask(res.data));
          dispatch(setFetchAgain(!fetchAgain));
          setRenameLoading(false);
          // // console.log(`data`);
          // // console.log(res.data);
          // // console.log(`data`);
        });
    } catch (error) {
      // toast({
      //   title: error.message,
      //   status: "error",
      //   duration: 5000,
      //   isClosable: true,
      //   position: "top-left",
      // });
      setRenameLoading(false);
    }

    // setGroupChatName("");
  };
  useEffect(() => {
    // console.log(`selectedChat`);
    // console.log(selectedChat);
    // console.log(user._id);
    if (selectedChat?.groupAdmin) {
      if (
        selectedChat?.groupAdmin?.some((e) => e._id === user._id) ||
        selectedChat?.groupAdmin?.some((e) => e === user._id)
      ) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    }
    if (selectedChat?.groupSuper?.length > 0) {
      if (
        selectedChat?.groupSuper?.some((e) => e._id === user._id) ||
        selectedChat?.groupSuper?.some((e) => e === user._id)
      ) {
        setIsSuper(true);
      } else {
        setIsSuper(false);
      }
    } else {
      if (
        selectedChat?.groupSuper?._id === user._id ||
        selectedChat?.groupSuper === user._id
      ) {
        setIsSuper(true);
      } else {
        setIsSuper(false);
      }
    }
    if (selectedChat?.description) {
      setInitialDescription(selectedChat.description);
    }
  }, [selectedChat]);
  const handleAddUser = async ({ userToAdd, chatId }) => {
    setLoading(true);
    // // console.log(selectedChat?.groupAdmin._id + " ::: " + user._id);
    if (selectedChat?.users.find((u) => u._id === userToAdd._id)) {
      toast({
        title: `User already chatting...`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-left",
      });
      return;
    }
    // if (isAdmin && selectedChat?.groupSuper?._id != user._id) {
    //   toast({
    //     title: `You're not allowed to add a new userz ${isAdmin.toString()}`,
    //     status: "error",
    //     duration: 5000,
    //     isClosable: true,
    //     position: "top-left",
    //   });
    //   return;
    // }
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await axios
        .put(
          `${mainPath}/api/chat/groupadd`,
          {
            chatId: selectedChat?._id,
            userId: userToAdd._id,
            who: user._id,
          },
          config
        )
        .then((res) => {
          //
          res.data.users.map((u) => {
            sendInvitation({
              user: u,
              from: user.email,
              title: res.data.chatName,
            });
          });
          //
          setSelectedChat(res.data);
          dispatch(setFetchAgain(!fetchAgain));
          setRenameLoading(false);
          setLoading(false);
        });
    } catch (error) {
      toast({
        title: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-left",
      });
      setRenameLoading(false);
    }
  };

  const handleRemoveUser = async (userToRemove) => {
    if (isAdmin && selectedChat?.groupSuper._id !== user._id) {
      toast({
        title: `You're not allowed to remove users`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-left",
      });
      return;
    }
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await axios.put(
        `${mainPath}/api/chat/groupremove`,
        {
          chatId: selectedChat?._id,
          userId: userToRemove._id,
          who: user._id,
        },
        config
      );
      userToRemove._id === user._id ? setSelectedChat() : setSelectedChat(data);
      dispatch(setFetchAgain(!fetchAgain));
      setRenameLoading(false);
      setLoading(false);
    } catch (error) {
      toast({
        title: `here: ${error.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-left",
      });
      setRenameLoading(false);
    }
  };

  const handleSetSuper = async ({ userToAdd, chatId }) => {
    // if (isAdmin && selectedChat?.groupSuper?._id != user._id) {
    //   toast({
    //     title: `You're not allowed to add a new userz ${isAdmin.toString()}`,
    //     status: "error",
    //     duration: 5000,
    //     isClosable: true,
    //     position: "top-left",
    //   });
    //   return;
    // }
    try {
      setSuperLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await axios
        .put(
          `${mainPath}/api/chat/setsuper`,
          {
            chatId: selectedChat?._id,
            superId: userToAdd._id,
            who: user._id,
          },
          config
        )
        .then((res) => {
          sendInvitation({
            user: userToAdd,
            from: user.email,
            title: res.data.chatName,
          });
          setSelectedChat(res.data);
          setSuperLoading(false);
          dispatch(setFetchAgain(!fetchAgain));
          setRenameLoading(false);
        });
    } catch (error) {
      toast({
        title: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-left",
      });
      setRenameLoading(false);
    }
  };
  useEffect(() => {
    // // console.log(`isUpdateChatModalOpen: ${isUpdateChatModalOpen}`);
    if (isUpdateChatModalOpen) {
      setEndDate(selectedChat?.dateTo);
      setGroupSuper(selectedChat?.groupSuper?.name);
      setGroupChatName(selectedChat?.chatName);
      onOpen();
    } else {
      onClose();
    }
  }, [isUpdateChatModalOpen]);
  useEffect(() => {
    // // console.log(selectedChat);
    setGroupChatName(selectedChat?.chatName);
    setGroupSuper(selectedChat?.groupSuper?.name);
  }, [selectedChat]);
  const openModal = () => {
    dispatch(setIsUpdateChatModalOpen(true));
  };
  const closeModal = () => {
    dispatch(setIsUpdateChatModalOpen(false));
  };

  const handleEditorChange = (value, editor) => {
    setDescription(value);
  };
  if (selectedChat?.users) {
    content = (
      <>
        <Modal size={"lg"} isOpen={isOpen} isCentered onClose={closeModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader padding={"20px"}> </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack>
                {modalMode !== "date" && (
                  <>
                    <FormControl display="flex">
                      <VStack w={"100%"} align={"start"}>
                        <p className="small gray">Nazwa zadania: </p>

                        <HStack w={"100%"} align={"start"}>
                          <Input
                            placeholder="Chat name"
                            mb={3}
                            value={groupChatName}
                            onChange={(e) => setGroupChatName(e.target.value)}
                          />

                          <Button
                            isDisabled={!isAdmin}
                            variant="solid"
                            colorScheme="teal"
                            ml={1}
                            isLoading={renameLoading}
                            onClick={handleRename}
                          >
                            Zapisz
                          </Button>
                        </HStack>
                      </VStack>
                    </FormControl>

                    <Box p={1}>
                      <Switch
                        onChange={() => setShowDescription(!showDescription)}
                        size="sm"
                      />{" "}
                      dodatkowy opis
                    </Box>
                    {showDescription && (
                      <>
                        <Editor
                          initialValue={initialDescription}
                          onEditorChange={handleEditorChange}
                          apiKey="86qkoj22lzv8vprxhr5f3yynaykasmyhvwilm485kime0iwp"
                          init={{
                            selector: "textarea", // Change this value according to your HTML
                            height: 250,
                            language: "pl",
                            language_load: true, // Disables the automatic loading of additional plugin and theme language files.

                            plugins:
                              "anchor autolink charmap codesample emoticons link lists searchreplace table visualblocks wordcount linkchecker",
                            toolbar:
                              "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat",
                          }}
                        />
                        <Button
                          isDisabled={!isAdmin}
                          variant="solid"
                          colorScheme="teal"
                          w={"100%"}
                          isLoading={descriptionChangeLoading}
                          onClick={handleDescriptionChange}
                        >
                          Zapisz
                        </Button>
                      </>
                    )}
                  </>
                )}
                <FormControl display="flex">
                  <VStack w={"100%"} align={"start"}>
                    <p className="small gray">Data rozpoczęcia: </p>
                    <HStack w={"100%"} align={"start"}>
                      <Box
                        border="1px solid #eaeaea"
                        borderRadius="5px"
                        p={2}
                        w="100%"
                        mb={3}
                      >
                        <DatePicker
                          isDisabled={isAdmin && true}
                          width="100%"
                          textAlign={"center"}
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                        />
                      </Box>
                      <Button
                        isDisabled={!isAdmin}
                        variant="solid"
                        colorScheme="teal"
                        ml={1}
                        isLoading={dateChangeLoading}
                        onClick={handleDateChange}
                      >
                        Zapisz
                      </Button>
                    </HStack>
                  </VStack>
                </FormControl>
                <FormControl display="flex">
                  <VStack w={"100%"} align={"start"}>
                    <p className="small gray">Data zakończenia: </p>
                    <HStack w={"100%"} align={"start"}>
                      <Box
                        border="1px solid #eaeaea"
                        borderRadius="5px"
                        p={2}
                        w="100%"
                        mb={3}
                      >
                        <DatePicker
                          isDisabled={isAdmin && true}
                          width="100%"
                          textAlign={"center"}
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                        />
                      </Box>
                      <Button
                        isDisabled={!isAdmin}
                        variant="solid"
                        colorScheme="teal"
                        ml={1}
                        isLoading={dateChangeLoading}
                        onClick={handleDateChange}
                      >
                        Zapisz
                      </Button>
                    </HStack>
                  </VStack>
                </FormControl>
              </VStack>
              {modalMode !== "date" && (
                <Tabs variant="soft-rounded">
                  <TabList w={"100%"}>
                    <Tab w={"50%"}>Kierownik</Tab>
                    <Tab w={"50%"}>Pracownicy</Tab>
                  </TabList>
                  <TabPanels w={"100%"}>
                    <TabPanel>
                      {superLoading ? (
                        <Spinner ml="auto" display="flex" />
                      ) : (
                        <p className="small gray">
                          kierownik: <b>{selectedChat?.groupSuper?.name}</b>
                        </p>
                      )}
                      {isAdmin && (
                        <SearchUser
                          paddingBottom={"30px"}
                          title={"Wybierz kierownika"}
                          setter={handleSetSuper}
                        />
                      )}
                    </TabPanel>
                    <TabPanel>
                      <Box w="100%" display="flex" flexWrap="wrap" pb={3}>
                        {selectedChat?.users.map((u) => (
                          <UserBadgeItem
                            key={u._id}
                            user={u}
                            handleFunction={() => handleRemoveUser(u)}
                          />
                        ))}
                        {loading && <Spinner ml="auto" display="flex" />}
                      </Box>
                      {(isAdmin || isSuper) && (
                        <SearchUser
                          paddingBottom={"30px"}
                          title={"Dodaj pracownika"}
                          setter={handleAddUser}
                        />
                      )}
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              )}
            </ModalBody>

            <ModalFooter>
              {isAdmin && modalMode !== "date" && (
                <DelTask chat={selectedChat} />
              )}
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  }
  return <>{content}</>;
};

export default UpdateGroupChatModal;
