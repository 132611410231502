import { createSlice } from "@reduxjs/toolkit";

export const isAttachmentsModalOpenSlice = createSlice({
  name: "isAttachmentsModalOpen",
  initialState: {
    isAttachmentsModalOpen: false,
  },
  reducers: {
    setIsAttachmentsModalOpen: (state, action) => {
      state.isAttachmentsModalOpen = action.payload;
    },
  },
});

export const { setIsAttachmentsModalOpen } =
  isAttachmentsModalOpenSlice.actions;
export default isAttachmentsModalOpenSlice.reducer;
