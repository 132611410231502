import { Button } from "@chakra-ui/button";
import React, { useEffect, useState } from "react";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/input";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Stack, HStack, VStack } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { mainPath } from "../../config/AxiosConfig";

const Signup = () => {
  const [show, setShow] = useState(false);
  const [name, setName] = useState([]);
  const [email, setEmail] = useState([]);
  const [password, setPassword] = useState([]);
  const [confirmPassword, setConfirmPassword] = useState([]);
  const [pic, setPic] = useState([]);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const history = useHistory();

  const handleClick = () => {
    setShow(!show);
  };

  const postDetails = (pics) => {
    // console.log("uploadding picture1...");
    setLoading(true);
    if (pics === undefined) {
      toast({
        title: "Selecta picture.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (
      pics.type === "image/jpeg" ||
      pics.type === "image/png" ||
      pics.type === "image/png"
    ) {
      // console.log("uploadding picture2...");
      const data = new FormData();
      data.append("file", pics);
      data.append("upload_preset", "mern");
      data.append("cloud_name", "dvdc69u5s");
      fetch(
        "https://collection.cloudinary.com/dvdc69u5s/a8288585064b2e07622009af02b4fb4e",
        {
          method: "post",
          body: data,
        },
      )
        .then((res) => res.json())
        .then((data) => {
          // console.log("uploadding picture3...");
          setPic(data.url.toString());
          setLoading(false);
        })
        .catch((err) => {
          // console.log("uploadding picture4...");
          // console.log(err);
          setLoading(false);
        });
    } else {
      toast({
        title: "Picture is in the wrong format.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
  };

  const submitHandler = async () => {
    setLoading(true);
    if (!name || !email || !password || !confirmPassword) {
      toast({
        title: "Please fill all the fields...",
        status: "warning",
        duration: "3000",
        isClosable: true,
      });
      return;
    }
    if (password !== confirmPassword) {
      toast({
        title: "Passwords are not the same...",
        status: "warning",
        duration: "3000",
        isClosable: true,
      });
      return;
    }
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      const { data } = await axios
        .post(
          `${mainPath}/api/user`,
          {
            name,
            email,
            password,
          },
          config,
        )
        .then((res) => {
          console.log(res.data);
          localStorage.setItem("userInfo", JSON.stringify(res.data));
          setLoading(false);
          history.push("/chats");
        });
      // toast({
      //   title: "Created new user",
      //   status: "sucess",
      //   duration: "3000",
      //   isClosable: true,
      // });
    } catch (error) {
      console.log(error);
      toast({
        title: "Something went wrong.",
        description: "error.response.data.message",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  return (
    <VStack spacing="5px">
      <FormControl id="first-name" isRequired>
        <FormLabel>Nazwa</FormLabel>
        <Input placeholder="Nazwa" onChange={(e) => setName(e.target.value)} />
      </FormControl>
      <FormControl id="email" isRequired>
        <FormLabel>Email</FormLabel>
        <Input placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
      </FormControl>
      <FormControl id="password" isRequired>
        <FormLabel>Hasło</FormLabel>
        <InputGroup size="md">
          <Input
            placeholder="Hasło"
            type={show ? "text" : "password"}
            onChange={(e) => setPassword(e.target.value)}
          />
          <InputRightElement width="4,5rem">
            <Button
              bgColor="transparent"
              h="1,75rem"
              size="sm"
              p={3}
              onClick={handleClick}
            >
              {show ? "Ukryj" : "Pokaż"}
            </Button>
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <FormControl id="confirmPassword" isRequired>
        <FormLabel>Potwierdź hasło</FormLabel>
        <InputGroup size="md">
          <Input
            placeholder="Hasło"
            type={show ? "text" : "password"}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <InputRightElement width="4,5rem">
            <Button
              bgColor="transparent"
              h="1,75rem"
              size="sm"
              p={3}
              onClick={handleClick}
            >
              {show ? "Ukryj" : "Pokaż"}
            </Button>
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <FormControl id="pic">
        <FormLabel>Avatar</FormLabel>
        <Input
          className="myFile"
          type="file"
          p={1.5}
          accept="image/*"
          onChange={(e) => postDetails(e.target.files[0])}
        />
      </FormControl>

      <Button
        marginTop={"20px"}
        colorScheme="blue"
        width="100%"
        onClick={submitHandler}
        isLoading={loading}
      >
        Utwórz
      </Button>
    </VStack>
  );
};

export default Signup;
