import { HStack, Input, InputGroup, InputRightElement } from "@chakra-ui/react";

import { faCalendarDay } from "@fortawesome/free-solid-svg-icons";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const CalendarTaskSearch = ({ startDate, endDate }) => {
  return (
    <HStack>
      <InputGroup>
        <Input
          name="nameInput"
          borderRadius="full"
          placeholder="szukaj zadania..."
          size="sm"
        />
        <InputRightElement padding="0px 5px 7px 5px">
          <FontAwesomeIcon color="gray" icon={faMagnifyingGlass} />
        </InputRightElement>
      </InputGroup>
    </HStack>
  );
};

export default CalendarTaskSearch;
