import { Box, Button, Collapse, HStack, VStack } from "@chakra-ui/react";
import Event from "./Event";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import AddEvent from "./AddEvent";
import { useState } from "react";

const EventHour = ({ date, cid, hour, events }) => {
  const [show, setShow] = useState(false);
  const [addContent, setAddContent] = useState(<></>);
  const addHandler = () => {
    setShow(!show);
    console.log("addHandler");
    setAddContent(<AddEvent date={date} cid={cid} hour={hour} />);
  };

  return (
    <Box borderRadius={3} bg={"#fafafa"} w={"100%"} p={"5px"} m={"4px 0px"}>
      <HStack>
        <Button
          onClick={() => {
            addHandler();
          }}
          bg={"blue.200"}
          size={"sm"}
        >
          <FontAwesomeIcon color="white" icon={faPlus} />
        </Button>
        <p className="small gray">{hour}:00 </p>
        {events.length > 0 ? (
          <VStack>
            {events?.map((e, index) => (
              <Event index={index} event={e} />
            ))}
          </VStack>
        ) : (
          <Event />
        )}
      </HStack>
      <Collapse in={show}>{addContent}</Collapse>
    </Box>
  );
};

export default EventHour;
