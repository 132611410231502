import { createSlice } from "@reduxjs/toolkit";

export const showDoneSlice = createSlice({
  name: "showDone",
  initialState: {
    showDone: true,
  },
  reducers: {
    setShowDone: (state, action) => {
      state.showDone = action.payload;
    },
  },
});

export const { setShowDone } = showDoneSlice.actions;
export default showDoneSlice.reducer;
