import { createSlice } from "@reduxjs/toolkit";

export const fetchMessagesAgainSlice = createSlice({
  name: "fetchMessagesAgain",
  initialState: {
    fetchMessagesAgain: false,
  },
  reducers: {
    setFetchMessagesAgain: (state, action) => {
      state.fetchMessagesAgain = action.payload;
    },
  },
});

export const { setFetchMessagesAgain } = fetchMessagesAgainSlice.actions;
export default fetchMessagesAgainSlice.reducer;
