import {
  Text,
  Box,
  Tooltip,
  Button,
  Menu,
  MenuButton,
  MenuList,
  Avatar,
  MenuItem,
  MenuDivider,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Input,
  useToast,
  Spinner,
  VStack,
} from "@chakra-ui/react";
import { BellIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { useDisclosure } from "@chakra-ui/hooks";
import React, { useEffect, useState } from "react";
import { ContextScope } from "../../Context/ContextProvider";
import logo from "../../images/notatka-logo.png";
import ProfileModal from "./ProfileModal";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { mainPath } from "../../config/AxiosConfig";
import ChatLoading from "../ChatLoading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import UserListItem from "../UserAvatar/UserListItem";
import MyPrivateChats from "../MyPrivateChats";
import { faGrip } from "@fortawesome/free-solid-svg-icons";
import { faGripLines } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { setFetchAgain } from "../slices/fetchAgainSlice";
import SearchUser from "../users/SearchUser";
import { setUserInfo } from "../slices/userInfoSlice";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";

const SideDrawer = ({
  from,
  setChats,
  setSelectedChat,
  alert,
  setAlert,
  privateChats,
  setPrivateChats,
}) => {
  const dispatch = useDispatch();
  const [content, setContent] = useState(<></>);
  const [search, setSearch] = useState();
  const [searchResult, setSearchResult] = useState();
  const [loading, setLoading] = useState();
  const [loadingChat, setLoadingChat] = useState();
  const user = useSelector((state) => state.userInfo.userInfo);
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const logoutHandler = () => {
    setChats([]);
    setSelectedChat({});
    setPrivateChats([]);
    localStorage.removeItem("userInfo");
    dispatch(setUserInfo({}));
    dispatch(setFetchAgain(true));
    localStorage.removeItem("userInfo");
    localStorage.clear();
    history.push("/");
  };

  const toast = useToast();

  const zzz = () => {
    onOpen();
  };
  useEffect(() => {
    if (user?.email === "") history.push("/");
    setContent(<Spinner size="xl" />);
  }, []);
  useEffect(() => {
    setContent(
      <>
        <Box className="topDrawerBox" key="b1">
          {from != "calendar" ? (
            <Link to={`../calendar`} target="_parent" rel="noopener noreferer">
              <Button
                p={(5, 0)}
                borderRadius={"full"}
                bg={"#25bdf5"}
                color={"gray.200"}
                size={"lg"}
              >
                <FontAwesomeIcon size={"lg"} color="white" icon={faGrip} />
              </Button>
            </Link>
          ) : (
            <Link to={`../chats`} target="_parent" rel="noopener noreferer">
              <Button
                p={(5, 0)}
                borderRadius={"full"}
                bg={"#25bdf5"}
                color={"gray.200"}
                size={"lg"}
              >
                <FontAwesomeIcon size={"lg"} color="white" icon={faGripLines} />
              </Button>
            </Link>
          )}
          <Box
            w={"60px"}
            h={"60px"}
            style={{
              backgroundImage: `url(${logo})`,
              backgroundSize: `30px`,
              backgroundRepeat: `no-repeat`,
              backgroundPosition: `20px 20px`,
            }}
          ></Box>
          <div className={"topPanel"}>
            <Menu>
              <MenuButton p={1}>
                <FontAwesomeIcon color="white" icon={faBell} />
              </MenuButton>
              {/* <MenuList></MenuList> */}
            </Menu>
            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                <Avatar
                  size="sm"
                  cursor="poiner"
                  name={user?.name}
                  src={user?.pic}
                ></Avatar>
              </MenuButton>
              <MenuList>
                <ProfileModal user={user}>
                  <MenuItem>
                    <VStack alignItems={"left"} alignContent={"left"}>
                      <p align={"left"}>Mój profil:</p>
                      <p className="small gray">{user?.email}</p>
                    </VStack>
                  </MenuItem>
                </ProfileModal>
                <MenuDivider />
                <MenuItem onClick={logoutHandler}>
                  <Button>wyloguj</Button>
                </MenuItem>
              </MenuList>
            </Menu>
          </div>
        </Box>
      </>
    );
  }, [user]);
  return (
    <>
      <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>wiadomości</DrawerHeader>
          <DrawerBody></DrawerBody>
        </DrawerContent>
      </Drawer>
      {content}
    </>
  );
};

export default SideDrawer;
