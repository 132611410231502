import { createSlice } from "@reduxjs/toolkit";

export const drawerModeSlice = createSlice({
  name: "drawerMode",
  initialState: {
    drawerMode: "",
  },

  reducers: {
    setDrawerMode: (state, action) => {
      state.drawerMode = action.payload;
    },
  },
});

export const { setDrawerMode } = drawerModeSlice.actions;

export default drawerModeSlice.reducer;
