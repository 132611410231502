import { createSlice } from "@reduxjs/toolkit";

export const searchObjectSlice = createSlice({
  name: "searchObject",
  initialState: {
    searchObject: {},
  },
  reducers: {
    setSearchObject: (state, action) => {
      state.searchObject = action.payload;
    },
  },
});

export const { setSearchObject } = searchObjectSlice.actions;
export default searchObjectSlice.reducer;
