import { Box } from "@chakra-ui/react";
import React from "react";
import { ContextScope } from "../../Context/ContextProvider";
import { useSelector } from "react-redux";
import NotesScroll from "./NotesScroll";

const NotesBox = () => {
  const fetchAgain = useSelector((state) => state.fetchAgain.fetchAgain);
  const { selectedChat } = ContextScope();
  return (
    <Box
      bg={"transparent"}
      display={{ base: selectedChat ? "flex" : "none", md: "flex" }}
      alignItems="center"
      flexDir="column"
      p={3}
      maxWidth={"100%"}
      height={"100%"}
      borderRadius="lg"
      className="notesBox"
    >
      <NotesScroll />
    </Box>
  );
};

export default NotesBox;
