import { ContextScope } from "../../Context/ContextProvider";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useDisclosure,
  Button,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { mainPath } from "../../config/AxiosConfig";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFetchAgain } from "../slices/fetchAgainSlice";
import { setIsUpdateChatModalOpen } from "../slices/isUpdateChatModalOpenSlice";
import { setSelectedTask } from "../slices/selectedTaskSlice";

const DelTask = ({ chat  }) => {
  const user = useSelector((state) => state.userInfo.userInfo);
const fetchAgain = useSelector((state) => state.fetchAgain.fetchAgain);
const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();
  const handleDel = () => {
    onClose();
    deleteTask();
    setLoading(true);
    //    handleRemoveUser(user)
  };
  const deleteTask = async () => {
    // if (chat?.groupAdmin.some((e) => e === user._id)) {
    //   toast({
    //     title: `You're not allowed to remove users`,
    //     status: "error",
    //     duration: 5000,
    //     isClosable: true,
    //     position: "top-left",
    //   });
    //   return;
    // }
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await axios.put(
        `${mainPath}/api/chat/delete`,
        {
          chatId: chat._id,
        },
        config,
      );
      // console.log(data);
      if (data) {
        // dispatch(setSelectedTask("all"));
        dispatch(setFetchAgain(!fetchAgain));
        setLoading(false);
        dispatch(setIsUpdateChatModalOpen(false))
      }
    } catch (error) {
      // console.log(error);
      toast({
        title: `here: ${error.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-left",
      });
      //   setRenameLoading(false);
    }
  };
  return (
    <>
      <Button isLoading={loading} colorScheme="red" onClick={onOpen}>
        Usuń zadanie
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Usuń zadanie
            </AlertDialogHeader>

            <AlertDialogBody>
              Czy usunąć zadanie i związane z nim notatki? Tego działania nie
              można cofnąć.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                anuluj
              </Button>
              <Button colorScheme="red" onClick={handleDel} ml={3}>
                Usuń
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default DelTask;
