import { createSlice } from "@reduxjs/toolkit";
import { format } from "date-fns";

const min = new Date();
const max = new Date();
min.setDate(min.getDate() - 30);
max.setDate(max.getDate() + 30);
const formatedMin = format(min, "yyyy/MM/dd");
const formatedMax = format(max, "yyyy/MM/dd");
const daysCount = Math.floor((max - min) / 86400000);

export const selectedDaySlice = createSlice({
  name: "selectedDay",
  initialState: {
    selectedDay: { min: formatedMin, max: formatedMax, daysCount: daysCount },
  },

  reducers: {
    setSelectedDay: (state, action) => {
      state.selectedDay = action.payload;
    },
  },
});

export const { setSelectedDay } = selectedDaySlice.actions;

export default selectedDaySlice.reducer;
