import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { mainPath } from "../../config/AxiosConfig";
import { Box, CircularProgress } from "@chakra-ui/react";
import EventHour from "./EventHour";

const EventsComponent = ({ cid, user, date }) => {
  const fetchAgain = useSelector((state) => state.fetchAgain.fetchAgain);
  const selectedTask = useSelector((state) => state.selectedTask.selectedTask);
  const hours = [];
  for (let i = 0; i < 24; i++) {
    hours.push(i);
  }
  const [content, setContent] = useState(<CircularProgress />);
  const getAlarms = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await axios
        .post(
          `${mainPath}/api/event/${date}`,
          {
            uid: user._id,
            date: date,
            cid: selectedTask?._id ? selectedTask._id : cid,
          },
          config
        )
        .then((res) => {
          setContent(
            hours?.map((e, index) => {
              const events = res.data?.filter((event) => event.hour === e);
              // console.log(`events`);
              // console.log(events);
              // console.log(`events`);
              return (
                <EventHour date={date} cid={cid} hour={e} events={events} />
              );
            })
          );
        });
    } catch (error) {}
  };
  useEffect(() => {
    console.log(`selectedTask: ${selectedTask._id}`);
    console.log(`user: ${user}`);
    console.log(`date: ${date}`);
    setContent(<CircularProgress isIndeterminate color="blue.100" />);
    console.log(date);
    getAlarms();
  }, [date]);
  useEffect(() => {
    setContent(<CircularProgress isIndeterminate color="blue.100" />);
    if (selectedTask === cid) {
      getAlarms();
    }
  }, [fetchAgain]);
  return (
    <Box
      marginTop={"15px"}
      w={"100%"}
      border={"1px solid #efefef"}
      borderRadius={5}
      p={3}
      h={"50vh"}
      overflowY={"auto"}
    >
      {content}
    </Box>
  );
};

export default EventsComponent;
