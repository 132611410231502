import { HStack, Input, InputGroup, InputRightElement } from "@chakra-ui/react";

import { faCalendarDay } from "@fortawesome/free-solid-svg-icons";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { setSelectedDay } from "../slices/selectedDaySlice";
import { setFetchAgain } from "../slices/fetchAgainSlice";
import { setSelectedTask } from "../slices/selectedTaskSlice";
const DatesRange = ({ startDate, endDate }) => {
  const selectedDay = useSelector((state) => state.selectedDay.selectedDay);
  const fetchAgain = useSelector((state) => state.fetchAgain.fetchAgain);
  const dispatch = useDispatch();
  return (
    <HStack
      className="datepickerwraper"
      p={2}
      bg={"#efefef"}
      borderRadius={"full"}
    >
      <HStack className="calendardatepicker">
        <DatePicker
          className="datepicker"
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          alignItems={"center"}
          width="150px"
          textAlign={"center"}
          selected={selectedDay.min}
          onChange={(date) => {
            // console.log(selectedDay);
            const newDate = format(date, "yyyy/MM/dd");
            const daysCount = Math.floor(
              (new Date(format(selectedDay.max, "yyyy/MM/dd")) -
                new Date(format(newDate, "yyyy/MM/dd"))) /
                86400000
            );
            dispatch(
              setSelectedDay({
                min: newDate,
                max: selectedDay.max,
                daysCount: daysCount,
              })
            );
            dispatch(setSelectedTask("all"));
            dispatch(setFetchAgain(!fetchAgain));
            // console.log(format(date, "yyyy/MM/dd"));
            // console.log(format(date, "yyyy/MM/dd"));
          }}
        />
        <FontAwesomeIcon
          paddingBottom={"15px"}
          marginBottom={"15px"}
          color="gray"
          icon={faCalendarDay}
        />
      </HStack>
      <HStack className="calendardatepicker">
        <DatePicker
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          className="datepicker"
          alignItems={"center"}
          width="150px"
          textAlign={"center"}
          selected={selectedDay.max}
          onChange={(date) => {
            // console.log(selectedDay);
            const newDate = format(date, "yyyy/MM/dd");
            const daysCount = Math.floor(
              (new Date(format(newDate, "yyyy/MM/dd")) -
                new Date(format(selectedDay.min, "yyyy/MM/dd"))) /
                86400000
            );
            dispatch(
              setSelectedDay({
                min: selectedDay.min,
                max: newDate,
                daysCount: daysCount,
              })
            );
            dispatch(setSelectedTask("all"));
            dispatch(setFetchAgain(!fetchAgain));
            // console.log(format(date, "yyyy/MM/dd"));
            // console.log(format(date, "yyyy/MM/dd"));
          }}
        />
        <FontAwesomeIcon color="gray" icon={faCalendarDays} />
      </HStack>
      {/* <InputGroup w={"150px"}>
        <Input
          bg={"white"}
          size={"sm"}
          borderRadius={"full"}
          placeholder="data początkowa"
        />
        <InputRightElement padding="0px 5px 7px 5px">
          <FontAwesomeIcon
            paddingBottom={"15px"}
            marginBottom={"15px"}
            color="gray"
            icon={faCalendarDay}
          />
        </InputRightElement>
      </InputGroup>
      <InputGroup w={"150px"}>
        <Input
          bg={"white"}
          size={"sm"}
          borderRadius={"full"}
          placeholder="data końcowa"
        />
        <InputRightElement padding="0px 5px 7px 5px">
          <FontAwesomeIcon color="gray" icon={faCalendarDays} />
        </InputRightElement> 
      </InputGroup>*/}
    </HStack>
  );
};

export default DatesRange;
