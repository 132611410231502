import {
  Box,
  FormControl,
  HStack,
  Input,
  Switch,
  InputGroup,
  StepDescription,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Button } from "@chakra-ui/button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setFetchAgain } from "../slices/fetchAgainSlice";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { ContextScope } from "../../Context/ContextProvider";
import axios from "axios";
import { mainPath } from "../../config/AxiosConfig";
import UserListItem from "../UserAvatar/UserListItem";
import UserBadgeItem from "../UserAvatar/UserBadgeItem";
import SearchUser from "../users/SearchUser";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedTask } from "../slices/selectedTaskSlice";

import { Editor } from "@tinymce/tinymce-react";
import { setModalMode } from "../slices/modalModeSlice";
import { setIsGroupChatModalOpen } from "../slices/isGroupChatModalOpenSlice";

const GroupChatModal = ({ children }) => {
  const dispatch = useDispatch();
  const fetchAgain = useSelector((state) => state.fetchAgain.fetchAgain);
  const modalMode = useSelector((state) => state.modalMode.modalMode);
  const [endDate, setEndDate] = useState(new Date());
  const isGroupChatModalOpen = useSelector(
    (state) => state.isGroupChatModalOpen.isGroupChatModalOpen
  );
  const [groupChatName, setGroupChatName] = useState();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [supervisor, setSupervisor] = useState();
  const [search, setSearch] = useState("");
  const [showDescription, setShowDescription] = useState(false);
  const [description, setDescription] = useState(null);
  // const [superSearch, setSuperSearch] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  // const [searchSuperResult, setSearchSuperResult] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [superloading, setSuperLoading] = useState(false);
  const [one, setOne] = useState(1);
  const [two, setTwo] = useState(2);

  const toast = useToast();
  const user = useSelector((state) => state.userInfo.userInfo);
  const { chats, setChats, selectedChat, setSelectedChat } = ContextScope();

  const handleGroup = ({ userToAdd, chatId }) => {
    if (selectedUsers.some((e) => e._id === userToAdd._id)) {
      // if (selectedUsers.includes(userToAdd._id)) {
      toast({
        title: `user already added`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-left",
      });
      return;
    } else setSelectedUsers([...selectedUsers, userToAdd]);
  };

  const handleSuper = ({ userToAdd, chatId }) => {
    setSupervisor(userToAdd);
  };

  const sendInvitation = async ({ user, from, title }) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await axios
        .post(
          `${mainPath}/api/email/invitation`,
          {
            email: user.email,
            from: from,
            title: title,
          },
          config
        )
        .then((res) => {});
      // setChats([data, ...chats]);
      // onClose();
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    if (!groupChatName || !selectedUsers) {
      toast({
        title: `fill all the fields`,
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "top-left",
      });
      return;
    }
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      let level = 0;
      let parent = {};
      if (selectedChat?._id) {
        level = 1;
        parent = selectedChat?._id;
      }
      const { data } = await axios
        .post(
          `${mainPath}/api/chat/group`,
          {
            user: user,
            level: level,
            parent: parent,
            description: description,
            name: groupChatName,
            dateTo: endDate,
            users: JSON.stringify(selectedUsers.map((u) => u._id)),
            groupSuper: supervisor?._id,
          },
          config
        )
        .then((res) => {
          //
          res.data.users.map((u) => {
            sendInvitation({
              user: u,
              from: user.email,
              title: res.data.chatName,
            });
          });
          //

          dispatch(setModalMode("newTaskAdded"));
          dispatch(setFetchAgain(!fetchAgain));
          dispatch(setIsGroupChatModalOpen(false));
          toast({
            title: `Utworzono zadanie`,
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-left",
          });
        });
      // setChats([data, ...chats]);
      // onClose();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = (userToDelete) => {
    setSelectedUsers(
      selectedUsers.filter((sel) => sel._id !== userToDelete._id)
    );
  };
  const handleSuperDelete = (userToDelete) => {
    setSupervisor(null);
  };
  const closeModal = () => {
    dispatch(setIsGroupChatModalOpen(false));
    setSelectedChat({});
    // onClose();
    dispatch(setIsGroupChatModalOpen(false));
  };
  useEffect(() => {
    setSupervisor(null);

    // console.log(`selectedChat`);
    // console.log(selectedChat);
  }, [children]);
  useEffect(() => {
    handleSuper({ userToAdd: user, chatId: selectedChat?._id });
  }, [isGroupChatModalOpen]);
  const handleEditorChange = (value, editor) => {
    setDescription(value);
  };

  return (
    <>
      <Modal size={"xl"} isOpen={isGroupChatModalOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {/* {selectedChat?.chatName} */}
            {modalMode === "new" ? "Dodaj zadanie" : "Dodaj podzadanie"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl marginBottom={"5px"}>
              <Input
                placeholder="Nazwa zadania"
                mb={5}
                onChange={(e) => setGroupChatName(e.target.value)}
              />
            </FormControl>
            <Box p={1}>
              <Switch
                onChange={() => setShowDescription(!showDescription)}
                size="sm"
              />{" "}
              dodatkowy opis
            </Box>
            {showDescription && (
              <Editor
                onEditorChange={handleEditorChange}
                apiKey="86qkoj22lzv8vprxhr5f3yynaykasmyhvwilm485kime0iwp"
                init={{
                  selector: "textarea", // Change this value according to your HTML

                  language: "pl",
                  language_load: true, // Disables the automatic loading of additional plugin and theme language files.

                  height: 250,
                  plugins:
                    "anchor autolink charmap codesample emoticons link lists searchreplace table visualblocks wordcount linkchecker",
                  toolbar:
                    "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat",
                }}
              />
            )}
            <Box height={"20px"}></Box>
            <FormControl>
              <HStack>
                <Text
                  color={"gray.400"}
                  margin={"0 0 10px 0"}
                  align={"right"}
                  width="50%"
                >
                  Data zakończenia:{" "}
                </Text>
                <Box
                  border="1px solid #eaeaea"
                  borderRadius="5px"
                  p={2}
                  w="50%"
                  mb={3}
                >
                  <DatePicker
                    textAlign={"center"}
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                  />
                </Box>
              </HStack>
            </FormControl>
            <HStack marginBottom={"20px"} w={"100%"}>
              <Tabs w={"100%"} variant="soft-rounded">
                <TabList w={"100%"}>
                  <Tab w={"50%"}>Uczestnicy</Tab>
                  <Tab w={"50%"}>Kierownik</Tab>
                </TabList>
                <TabPanels w={"100%"}>
                  <TabPanel w={"100%"}>
                    <VStack
                      w={"100%"}
                      minHeight={"200px"}
                      padding={3}
                      border={"1px solid #eaeaea"}
                      borderRadius={"10px"}
                      spacing={3}
                    >
                      <p className="small gray">Wybrani uczestnicy: </p>
                      <Box
                        marginBottom={"20px"}
                        w="100%"
                        display="flex"
                        flexWrap="wrap"
                      >
                        {selectedUsers.map((u) => (
                          <UserBadgeItem
                            key={user._id}
                            user={u}
                            handleFunction={() => handleDelete(u)}
                          />
                        ))}
                      </Box>
                      <SearchUser title={"Wyszukaj..."} setter={handleGroup} />
                    </VStack>
                  </TabPanel>
                  <TabPanel>
                    <VStack
                      w={"100%"}
                      minHeight={"200px"}
                      padding={3}
                      border={"1px solid #eaeaea"}
                      borderRadius={"10px"}
                      spacing={3}
                    >
                      <p marginTop={"20px"} className="small gray">
                        Kierownik:{" "}
                      </p>
                      <Box w="100%" display="flex" flexWrap="wrap">
                        {supervisor && (
                          <UserBadgeItem
                            key={supervisor._id}
                            user={supervisor}
                            handleFunction={() => handleSuperDelete(supervisor)}
                          />
                        )}
                      </Box>
                      <SearchUser
                        paddingBottom={"30px"}
                        title={"Wyszukaj..."}
                        setter={handleSuper}
                      />
                    </VStack>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </HStack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
              Zapisz
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default GroupChatModal;
