import { Box, Button, Table, Td, Tr } from "@chakra-ui/react";
import { mainPath } from "../../config/AxiosConfig";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { setFetchAgain } from "../slices/fetchAgainSlice";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

const AttachmentsTable = ({ user, files }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const fetchAgain = useSelector((state) => state.fetchAgain.fetchAgain);
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  // console.log(files);
  files?.map((f) => {
    // console.log(f?.caption);
  });

  const getAFile = async ({ fid, cid }) => {
    // console.log(fid);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await axios
        .post(
          // `${mainPath}/api/chat/sort/${sort}/${asc}`,
          `${mainPath}/api/file/get`,
          {
            fid: fid,
            uid: user._id,
            cid: cid,
          },
          config
        )
        .then((res) => {
          // console.log(`res`);
          // console.log(res);
          // console.log(`res`);
          openInNewTab(res.data.url);
        });
    } catch (error) {}
  };
  const handleDel = async ({ fid, cid }) => {
    setLoading(true);
    // console.log(fid);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await axios
        .post(
          // `${mainPath}/api/chat/sort/${sort}/${asc}`,
          `${mainPath}/api/file/delete`,
          {
            fid: fid,
            uid: user._id,
            cid: cid,
          },
          config
        )
        .then((res) => {
          if (res.data.message === "deleted") {
            dispatch(setFetchAgain(!fetchAgain));
            // // // // console.log(res.data);
            setLoading(false);
          }
        });
    } catch (error) {}
  };
  return (
    <>
      <Box
        p={0}
        w={"100%"}
        borderRadius="10px"
        className="tableBox"
        border={"1px solid #eee"}
        maxW={"98vw"}
        maxH={"90vh"}
        overflowX={"auto"}
        overflowY={"auto"}
      >
        <Table>
          {files?.length > 0 &&
            files?.map((f, index) => (
              <Tr>
                <Td w={"40px"}>
                  <Button
                    isLoading={loading}
                    onClick={() => {
                      handleDel({ fid: f._id, cid: f?.chat });
                    }}
                    size={"xs"}
                    bg={"transparent"}
                  >
                    <FontAwesomeIcon size="md" color="red" icon={faXmark} />
                  </Button>
                </Td>
                <Td w={"80px"}>
                  <p className="small gray">{index + 1}</p>
                </Td>
                <Td color={"blue.300"}>
                  <p
                    onClick={() => {
                      getAFile({ fid: f?._id, cid: f?.chat });
                    }}
                    className="link small hand"
                  >
                    <u>{f?.caption}</u>
                  </p>
                </Td>
              </Tr>
            ))}
        </Table>
      </Box>
    </>
  );
};

export default AttachmentsTable;
