import { Box, Button, HStack, VStack } from "@chakra-ui/react";
import DelNote from "./DelNotes";
import { format } from "date-fns";

const SingleNote = ({ note }) => {
  return (
    <Box
      margin={0}
      bg={"#fafad2"}
      borderBottom={"1px dashed #dadada"}
      borderRadius={"0px"}
      p={4}
    >
      <HStack>
        <DelNote chat={note} />
        {/* <Button bg={"transparent"} size={"sm"}>
          <FontAwesomeIcon className="hand" color="red" icon={faXmark} />
        </Button> */}
        <VStack align={"start"}>
          <p className="small gray">
            {format(new Date(note.createdAt.toString()), "yyyy-MM-dd")}
          </p>
          <p className="note">{note.content}</p>
        </VStack>
      </HStack>
    </Box>
  );
};

export default SingleNote;
