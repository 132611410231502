import { Button, useStatStyles } from "@chakra-ui/react";

import { faCalendarDay } from "@fortawesome/free-solid-svg-icons";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { mainPath } from "../../config/AxiosConfig";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { setFetchAgain } from "../slices/fetchAgainSlice";
const DataSaveComponent = ({ cid, target, newDate }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userInfo.userInfo);
  const fetchAgain = useSelector((state) => state.fetchAgain.fetchAgain);
  const [dateChangeLoading, setDateChangeLoading] = useState(false);
  const handleDateChange = async () => {
    setDateChangeLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await axios
        .put(
          `${mainPath}/api/chat/${
            target === "from" ? "dateFrom" : "datechange"
          }`,
          {
            chatId: cid,
            newDate: newDate,
          },
          config
        )
        .then((res) => {
          console.log(res.data);
          dispatch(setFetchAgain(!fetchAgain));
          setDateChangeLoading(false);
        });
    } catch (error) {
      setDateChangeLoading(false);
    }
  };
  return (
    <>
      {target === "from" ? (
        <Button
          w={"100%"}
          onClick={() => handleDateChange()}
          leftIcon={<FontAwesomeIcon color="#ffffff" icon={faCalendarDay} />}
          size={"sm"}
          colorScheme="linkedin"
          borderRadius={4}
          color={"white"}
          bg={"blue.500"}
        >
          datę początkową
        </Button>
      ) : (
        <Button
          w={"100%"}
          onClick={() => handleDateChange()}
          borderRadius={4}
          size={"sm"}
          leftIcon={<FontAwesomeIcon color="#ffffff" icon={faCalendarDays} />}
          colorScheme="linkedin"
          color={"white"}
          bg={"blue.500"}
        >
          datę końcową
        </Button>
      )}
    </>
  );
};

export default DataSaveComponent;
