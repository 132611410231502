import { createSlice } from "@reduxjs/toolkit";

export const modalModeSlice = createSlice({
  name: "modalMode",
  initialState: {
    modalMode: "new",
  },
  reducers: {
    setModalMode: (state, action) => {
      state.modalMode = action.payload;
    },
  },
});

export const { setModalMode } = modalModeSlice.actions;
export default modalModeSlice.reducer;
