import React, { useEffect } from "react";
import { Container, Box } from "@chakra-ui/react";
import Reset from "../components/Authentication/reset";

const Resetpage = () => {
  return (
    <Container maxW="400px" centerContent>
      <Box
        d="flex"
        justifyContent="center"
        p={6}
        w="100%"
        m="40px 0 15px 0"
        borderRadius="lg"
      ></Box>
      <Box w="100%" p={4} borderRadius="lg" borderWidth="1px">
        <Reset />
      </Box>
    </Container>
  );
};

export default Resetpage;
