import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import isChatDrawerOpenReducer from "../components/isChatDrawerOpenSlice";
import isAlarmModalOpenReducer from "../components/slices/isAlarmModalOpenSlice";
import isEventModalOpenReducer from "../components/slices/isEventModalOpenSlice";
import userInfoReducer from "../components/slices/userInfoSlice";
import newMessageIdReducer from "../components/slices/newMessageIdSlice";
import selectedDayReducer from "../components/slices/selectedDaySlice";
import selectedTaskReducer from "../components/slices/selectedTaskSlice";
import selectedAlarmReducer from "../components/slices/selectedAlarmSlice";
import selectedFileReducer from "../components/slices/selectedFileSlice";
import chatFilesReducer from "../components/slices/chatFilesSlice";
import isAddTaskModalOpenSliceReducer from "../components/slices/isAddTaskModalOpenSlice";
import isAttachmentsModalOpenSliceReducer from "../components/slices/isAttachmentsModalOpenSlice";
import showDoneSliceReducer from "../components/slices/showDoneSlice";
import isGroupChatModalOpenSliceReducer from "../components/slices/isGroupChatModalOpenSlice";
import isUpdateChatModalOpenSliceReducer from "../components/slices/isUpdateChatModalOpenSlice";
import modalModeSliceReducer from "../components/slices/modalModeSlice";
import fetchAgainReducer from "../components/slices/fetchAgainSlice";
import fetchMessagesAgainReducer from "../components/slices/fetchMessagesAgainSlice";
import searchObjectReducer from "../components/slices/searchObjectSlice";
import selectedUsersReducer from "../components/slices/selectedUsersSlice";
import drawerModeReducer from "../components/slices/drawerModeSlice";

export const store = configureStore({
  reducer: {
    isChatDrawerOpen: isChatDrawerOpenReducer,
    isAlarmModalOpen: isAlarmModalOpenReducer,
    isEventModalOpen: isEventModalOpenReducer,
    userInfo: userInfoReducer,
    newMessageId: newMessageIdReducer,
    drawerMode: drawerModeReducer,
    selectedTask: selectedTaskReducer,
    selectedDay: selectedDayReducer,
    selectedAlarm: selectedAlarmReducer,
    selectedFile: selectedFileReducer,
    chatFiles: chatFilesReducer,
    isAttachmentsModalOpen: isAttachmentsModalOpenSliceReducer,
    isAddTaskModalOpen: isAddTaskModalOpenSliceReducer,
    showDone: showDoneSliceReducer,
    isGroupChatModalOpen: isGroupChatModalOpenSliceReducer,
    isUpdateChatModalOpen: isUpdateChatModalOpenSliceReducer,
    fetchAgain: fetchAgainReducer,
    fetchMessagesAgain: fetchMessagesAgainReducer,
    searchObject: searchObjectReducer,
    modalMode: modalModeSliceReducer,
    selectedUsers: selectedUsersReducer,
  },
});

setupListeners(store.dispatch);
