import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/free-solid-svg-icons";
import { Button, HStack } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { setFetchMessagesAgain } from "../slices/fetchMessagesAgainSlice";
import { setSelectedTask } from "../slices/selectedTaskSlice";
import { setDrawerMode } from "../slices/drawerModeSlice";
import { setIsChatDrawerOpen } from "../isChatDrawerOpenSlice";
import axios from "axios";
import { mainPath } from "../../config/AxiosConfig";
import { useEffect, useState } from "react";
const CommentsComponent = ({
  rawChat,
  setSelectedChat,
  setSelectedPrivateChat,
}) => {
  const user = useSelector((state) => state.userInfo.userInfo);
  const fetchMessagesAgain = useSelector(
    (state) => state.fetchMessagesAgain.fetchMessagesAgain
  );
  const newMessageId = useSelector((state) => state.newMessageId.newMessageId);
  const [loading, setLoading] = useState(false);
  const [chat, setChat] = useState({});
  const fetchChatDetails = async () => {
    setLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await axios
        .post(
          // `${mainPath}/api/chat/sort/${sort}/${asc}`,
          `${mainPath}/api/chat/details`,
          {
            uid: user._id,
            cid: rawChat?._id,
          },
          config
        )
        .then((res) => {
          setChat(res.data[0]);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };
  const dispatch = useDispatch();
  const handleSubClick = async (target) => {
    setSelectedPrivateChat(null);
    dispatch(setSelectedTask(chat));
    dispatch(setDrawerMode(target));

    const openDrawer = (chat) => {
      // // console.log(isChatDrawerOpen);
      setSelectedChat(chat);
      dispatch(setIsChatDrawerOpen(true));
    };
    openDrawer(chat);
    if (target !== "chat") {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        };
        const { data } = await axios.put(
          `${mainPath}/api/chat/read`,
          {
            chatId: chat?._id,
          },
          config
        );

        // dispatch(setFetchAgain(!fetchAgain));
      } catch (error) {}
    }
  };
  useEffect(() => {
    fetchChatDetails();
  }, [rawChat]);
  useEffect(() => {
    if (rawChat._id === newMessageId?.newMessageId) fetchChatDetails();
    // console.log(`newMessageId`);
    // console.log(rawChat._id);
    // console.log(newMessageId);
    // console.log(`newMessageId`);
  }, [fetchMessagesAgain]);
  return (
    <Button
      isDisabled={loading}
      bg={chat.newMessages > 0 ? "#fadede" : "transparent"}
      p={"1px 21px 1px 15px"}
      size={"xs"}
      color="blue.400"
      onClick={() => handleSubClick("chats")}
      leftIcon={<FontAwesomeIcon color="#4bb2de" icon={faComments} />}
    >
      <HStack>
        <span padding={"0px 5px"} className="small lightgray">
          {chat.allMessages}
        </span>
        {chat.newMessages > 0 && (
          <text padding={"0px 5px"} className="small red">
            {chat.newMessages}
          </text>
        )}
      </HStack>
    </Button>
  );
};

export default CommentsComponent;
