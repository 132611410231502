import {
  Box,
  Button,
  Toast,
  Collapse,
  Text,
  useDisclosure,
  useToast,
  Tr,
  Td,
  HStack,
  VStack,
  Container,
} from "@chakra-ui/react";
import { getSender } from "../../config/ChatLogics";
import { setIsChatDrawerOpen } from "../isChatDrawerOpenSlice";
import { useDispatch, useSelector } from "react-redux";
import { ContextScope } from "../../Context/ContextProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import SubtasksList from "../subtasks/SubtasksList";

import axios from "axios";
import { mainPath } from "../../config/AxiosConfig";
import UpdateGroupChatModal from "../miscellaneous/UpdateGroupChatModal";

const PrivateComponent = ({ chat }) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [subtasks, setSubtasks] = useState([]);
  const [show, setShow] = useState(false);
  const { isOpen, onToggle } = useDisclosure();
  const user = useSelector((state) => state.userInfo.userInfo);
  const {
    alert,
    setAlert,
    selectedChat,
    setSelectedChat,
    setSelectedPrivateChat,
    chats,
    setChats,
    privateChats,
    setPrivateChats,
  } = ContextScope();
  const dispatch = useDispatch();
  const isChatDrawerOpen = useSelector(
    (state) => state.isChatDrawerOpen.isChatDrawerOpen
  );
  const openDrawer = (chat) => {
    setSelectedPrivateChat(chat);
    // dispatch(setIsChatDrawerOpen(true));
  };
  useEffect(() => {
    console.log(`chat`);
    console.log(chat);
    console.log(`chat`);
  }, [chat]);
  const getSubtasks = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      setLoading(true);
      const { data } = await axios.post(
        `${mainPath}/api/chat/subtasks/${chat._id}`,
        {
          chatId: chat._id,
          userId: user._id,
        },
        config
      );
      setSubtasks(data);
      setLoading(false);
    } catch (error) {
      // // console.log(error);
      toast({
        title: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
      setLoading(false);
    }
  };
  const setList = () => {
    getSubtasks();
    onToggle();
  };

  const handleSubClick = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      };
      const { data } = await axios.put(
        `${mainPath}/api/chat/read`,
        {
          chatId: chat?._id,
        },
        config
      );
    } catch (error) {}
  };
  return (
    <>
      <Tr>
        <Td
          className="hand privateChatList"
          onClick={() => {
            handleSubClick();
            openDrawer(chat);
          }}
        >
          {chat.chatName === user.name ? (
            <HStack>
              <b>{chat.users[0].name}</b>
              <span className="small gray">
                {" "}
                <Box
                  align={"center"}
                  w={"20px"}
                  h={"20px"}
                  borderRadius={"full"}
                  bg={"gray.200"}
                  color={"white"}
                >
                  {chat.allPrivateMessages}
                </Box>
              </span>
              <span className={"small red"}>
                <Box
                  w={"20px"}
                  h={"20px"}
                  borderRadius={"full"}
                  bg={"red.200"}
                  align={"center"}
                  color={"white"}
                >
                  {chat.newPrivateMessages}
                </Box>
              </span>
            </HStack>
          ) : (
            <HStack>
              <b>{chat.chatName}</b>
              <span className="small gray">
                {" "}
                <Box
                  align={"center"}
                  w={"20px"}
                  h={"20px"}
                  borderRadius={"full"}
                  bg={"gray.200"}
                  color={"white"}
                >
                  {chat.allPrivateMessages}
                </Box>
              </span>
              <span className={"small red"}>
                <Box
                  w={"20px"}
                  h={"20px"}
                  borderRadius={"full"}
                  bg={"red.200"}
                  align={"center"}
                  color={"white"}
                >
                  {chat.newPrivateMessages}
                </Box>
              </span>
            </HStack>
          )}
        </Td>
        {/* <Td className="hand privateChatList" onClick={() => openDrawer(chat)}>
          <Button
            color="#05cdff"
            rightIcon={<FontAwesomeIcon icon={faComment} />}
          >
            {chat.messages}
          </Button>
        </Td> */}
      </Tr>
    </>
  );
};

export default PrivateComponent;
