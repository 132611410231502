import { createSlice } from "@reduxjs/toolkit";

export const selectedUsersSlice = createSlice({
  name: "selectedUsers",
  initialState: {
    selectedUsers: [],
  },

  reducers: {
    setSelectedUsers: (state, action) => {
      state.selectedUsers = action.payload;
    },

    addToSelectedUsers: (state, action) => {
      return {
        ...state,
        selectedUsers: [...state.selectedUsers, action.payload],
      };
    },
    removeFromSelectedUsers: (state, action) => {
      // console.log(state.stIds);
      // // state.stIds.filter((event) => state.stId._id !== action.payload);
      return {
        ...state,
        selectedUsers: state.selectedUsers.map(
          (selectedUsers, index) => index !== action.payload.index,
        ),
      };
    },
  },
});

export const { setSelectedUsers, addToSelectedUsers, removeFromSelectedUsers } =
  selectedUsersSlice.actions;
export default selectedUsersSlice.reducer;
