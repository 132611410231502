import React from "react";
import ScrollableFeed from "react-scrollable-feed";
import {
  isLastMessage,
  isSameSender,
  isSameSenderMargin,
  isSameUser,
} from "../config/ChatLogics";
import { useSelector } from "react-redux";
import MessageComponent from "./MessageComponent";
import { Box } from "@chakra-ui/react";

const ScrollableChat = ({ messages }) => {
  const user = useSelector((state) => state.userInfo.userInfo);
  return (
    <ScrollableFeed height={300} forceScroll={false} className="ScrollableFeed">
      {/* {messages?.status !== "empty" ? ( */}
      {messages && messages.length > 0 ? (
        messages?.map((m, i) => (
          <MessageComponent
            isLastMessage={isLastMessage}
            isSameSender={isSameSender}
            isSameSenderMargin={isSameSenderMargin}
            isSameUser={isSameUser}
            messages={messages}
            m={m}
            i={i}
            user={user}
          />
        ))
      ) : (
        <Box p={3}>
          <p className="small gray">Tu nie ma jeszcze wiadomości...</p>
        </Box>
      )}
      {/* ) : (
        <Box p={3}>
          <p className="small gray">Tu nie ma jeszcze wiadomości...</p>
        </Box>
      )} */}
    </ScrollableFeed>
  );
};

export default ScrollableChat;
