import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Spinner,
  VStack,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { mainPath } from "../../config/AxiosConfig";
import { useHistory } from "react-router-dom";

const NewPasswordComponent = ({ email }) => {
  const history = useHistory();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [alert, setAlert] = useState("");
  const [reset, setReset] = useState(false);
  const passwordReset = async () => {
    // console.log("passwordReset");
    // console.log(password);
    // console.log(confirmPassword);
    if (password !== confirmPassword) {
      // console.log("tu1");
      setAlert("hasła nie pasują");
    } else if (password === "" || confirmPassword === "") {
      // console.log("tu2");
      setAlert("wprowadź hasło");
    } else if (password === confirmPassword) {
      // console.log("tu3");
      // console.log(email);
      setLoading(true);
      try {
        const { data } = await axios.post(
          `${mainPath}/api/user/password/reset`,
          {
            email: email,
            password: password,
          },
        );
        setLoading(false);
        toast({
          title: data.message,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "bottom-left",
        });
        setContent(data.message);
      } catch (error) {
        setLoading(false);
        setContent(error.response.data.message);
        // console.log(error.response);
        toast({
          title: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "bottom-left",
        });
      }
    }
  };
  const passwordChangeHandler = (e) => {
    setPassword(e.target.value);
    // console.log(e.target.value);
    // console.log(password);
  };
  const confirmPasswordChangeHandler = (e) => {
    setConfirmPassword(e.target.value);
    // console.log(e.target.value);
  };
  const [content, setContent] = useState(<Spinner />);
  const login = () => {
    history.push("/");
  };
  return (
    <>
      {!reset && (
        <VStack>
          <p>{alert}</p>
          <FormControl>
            <FormLabel>Nowe hasło: </FormLabel>
            <Input
              onChange={passwordChangeHandler}
              type="password"
              placeholder="wprowadź hasło"
            />
            <FormLabel>Powtórz hasło: </FormLabel>
            <Input
              onChange={confirmPasswordChangeHandler}
              type="password"
              placeholder="wprowadź ponownie hasło"
            />
            <Button
              isLoading={loading}
              marginTop="10px"
              colorScheme="blue"
              onClick={passwordReset}
            >
              Resetuj
            </Button>
          </FormControl>
        </VStack>
      )}
      {reset && (
        <VStack>
          <p>hasło zostało zmienione</p>
          <Button onClick={login} colorScheme="blue">
            zaloguj
          </Button>
        </VStack>
      )}
    </>
  );
};

export default NewPasswordComponent;
