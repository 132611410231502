import { format } from "date-fns";
import CalendarRows from "../components/calendarview/CalendarRows";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setIsChatDrawerOpen } from "../components/isChatDrawerOpenSlice";
import { setIsUpdateChatModalOpen } from "../components/slices/isUpdateChatModalOpenSlice";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { setDrawerMode } from "../components/slices/drawerModeSlice";

import { setUserInfo } from "../components/slices/userInfoSlice";
import SideDrawer from "../components/miscellaneous/SideDrawer";
import {
  Box,
  Button,
  HStack,
  Spinner,
  Tr,
  Td,
  Table,
  Text,
  Tbody,
  Thead,
  VStack,
  useDisclosure,
  useToast,
  Fade,
  ScaleFade,
  Slide,
  SlideFade,
  Collapse,
} from "@chakra-ui/react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import axios from "axios";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import DoubleScrollBar from "react-double-scrollbar";
import { mainPath } from "../config/AxiosConfig";
import { faComments } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import alarmwhite from "../images/alarmwhite.png";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import UserListItem from "../components/UserAvatar/UserListItem";

import ChatBox from "../components/ChatBox";
import PrivateChatBox from "../components/PrivateChatBox";
import ChatLoading from "../components/ChatLoading";
import NotesBox from "../components/notes/NotesBox";
import DataSaveComponent from "../components/calendarview/DataSaveComponent";
import AlarmsComponent from "../components/alarm/AlarmsComponent";
import { ContextScope } from "../Context/ContextProvider";
import SearchUser from "../components/users/SearchUser";
import UserBadgeItem from "../components/UserAvatar/UserBadgeItem";
import { setModalMode } from "../components/slices/modalModeSlice";
import CalendarTh from "../components/calendarview/CalendarTh";
import DatesRange from "../components/calendarview/DatesRange";
import CalendarTaskSearch from "../components/calendarview/CalendarTaskSearch";
import { setSelectedDay } from "../components/slices/selectedDaySlice";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import UpdateGroupChatModal from "../components/miscellaneous/UpdateGroupChatModal";
import EventsComponent from "../components/events/EventsComponent";
const Calendarpage = () => {
  const {
    setChats,
    selectedChat,
    setSelectedChat,
    alert,
    setAlert,
    privateChats,
    setPrivateChats,
  } = ContextScope();
  const selectedTask = useSelector((state) => state.selectedTask.selectedTask);
  const [notesPanel, setNotesPanel] = useState(false);
  const [chatPanel, setChatPanel] = useState(false);
  const [chatsPanel, setChatsPanel] = useState(false);
  const [notesColor, setNotesColor] = useState("gray.100");
  const [chatColor, setChatColor] = useState("gray.100");
  const [chatsColor, setChatsColor] = useState("gray.100");
  const selectedDay = useSelector((state) => state.selectedDay.selectedDay);
  const [selected, setSelected] = useState("zzz");
  const [loadingChat, setLoadingChat] = useState();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const [searchResult, setSearchResult] = useState();
  const [baseDate, setBaseDate] = useState();
  const [end, setEnd] = useState(new Date());
  const [daysCount, setDaysCount] = useState(30);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const fetchAgain = useSelector((state) => state.fetchAgain.fetchAgain);
  const [loading, setLoading] = useState(false);
  const [top, setTop] = useState(<></>);
  const [cid, setCid] = useState();
  const [title, setTitle] = useState(false);
  const [content, setContent] = useState();
  const [size, setSize] = useState("sm");
  const [taskDate, setTaskDate] = useState(false);
  const dispatch = useDispatch();
  const [tasks, setTasks] = useState([]);
  const toast = useToast();
  const modalMode = useSelector((state) => state.modalMode.modalMode);
  const { task } = useParams();
  const drawerMode = useSelector((state) => state.drawerMode.drawerMode);
  const isChatDrawerOpen = useSelector(
    (state) => state.isChatDrawerOpen.isChatDrawerOpen
  );

  const accessChat = async ({ userToAdd, chatId }) => {
    console.log(userToAdd);
    try {
      setLoadingChat(true);
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };

      const { data } = await axios.post(
        `${mainPath}/api/chat`,
        { userId: userToAdd._id, chatId },
        config
      );
      if (!privateChats.find((c) => c._id === data._id))
        setPrivateChats([data, ...privateChats]);
      setSelectedChat(data);
      setLoadingChat(false);
    } catch (error) {
      toast({
        title: "error accessing Chat",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
    }
  };
  const toogleNotes = () => {
    setNotesPanel(!notesPanel);
    if (notesPanel === true) {
      setNotesColor("gray.100");
    } else {
      setNotesColor("gray.300");
    }
  };
  const toogleChats = () => {
    if (chatsPanel === true) {
      setChatPanel(false);
      setChatsPanel(false);
      setChatColor("gray.100");
      setChatsColor("gray.100");
    } else {
      setChatPanel(false);
      setChatsPanel(true);
      setChatColor("gray.100");
      setChatsColor("gray.300");
    }
    // setChatPanel(chatsPanel);
    // setChatsPanel(!chatsPanel);
    // if (chatsPanel === true) {
    //   setChatColor("gray.300");
    //   setChatsColor("gray.100");
    // } else {
    //   setChatColor("gray.100");
    //   setChatsColor("gray.300");
    // }
  };
  const toogleChat = () => {
    if (chatPanel === true) {
      setChatPanel(false);
      setChatsPanel(false);
      setChatColor("gray.100");
      setChatsColor("gray.100");
    } else {
      setChatPanel(true);
      setChatsPanel(false);
      setChatColor("gray.300");
      setChatsColor("gray.100");
    }
    // setChatPanel(!chatPanel);
    // setChatsPanel(chatPanel);
    // if (chatPanel === true) {
    //   setChatColor("gray.100");
    //   setChatsColor("gray.300");
    // } else {
    //   setChatColor("gray.300");
    //   setChatsColor("gray.100");
    // }
  };
  const getTasks = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      setLoading(true);
      const { data } = await axios
        .post(
          `${mainPath}/api/chat/all`,
          {
            userId: user._id,
          },
          config
        )
        .then((res) => {
          // console.log(res.data);
          setBaseDate(new Date());
          setTasks(res.data);
          setLoading(false);
        });
    } catch (error) {
      // console.log(error);
      toast({
        title: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
      setLoading(false);
    }
  };
  const getATask = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      setLoading(true);
      const { data } = await axios
        .post(
          `${mainPath}/api/chat/single/${task}`,
          {
            userId: user._id,
          },
          config
        )
        .then((res) => {
          // console.log(res.data);
          setBaseDate(new Date(res.data?.dateFrom));
          const dF = new Date(res.data?.dateFrom);
          const dT = new Date(res.data?.dateTo);
          const daysCount = Math.floor(
            (new Date(format(dT, "yyyy/MM/dd")) -
              new Date(format(dF, "yyyy/MM/dd"))) /
              86400000
          );
          dispatch(
            setSelectedDay({
              min: dF,
              max: dT,
              daysCount: daysCount,
            })
          );
          setTasks(res.data);
          setLoading(false);
        });
    } catch (error) {
      // console.log(error);
      toast({
        title: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
      setLoading(false);
    }
  };
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("userInfo"));

    if (user) {
      dispatch(setUserInfo(user));
    }
  }, []);
  useEffect(() => {
    console.log(isChatDrawerOpen);
    if (isChatDrawerOpen == true) {
      if (drawerMode === "notes") {
        setNotesColor("gray.300");
      } else if (drawerMode === "chat") {
        setChatColor("gray.300");
        setChatsColor("gray.100");
      } else if (drawerMode === "chats") {
        setChatColor("gray.100");
        setChatsColor("gray.300");
      }
      setDrawerOpen(true);
    } else {
      dispatch(setIsChatDrawerOpen(false));
      dispatch(setDrawerMode(""));
    }
  }, [isChatDrawerOpen]);
  const closeChatDrawer = () => {
    dispatch(setDrawerMode(""));
    dispatch(setIsChatDrawerOpen(false));
    setDrawerOpen(false);
  };
  const user = useSelector((state) => state.userInfo.userInfo);
  useEffect(() => {
    if (user?.email) {
      if (task) {
        getATask();
      } else {
        getTasks();
      }
      setTop(
        <>
          <SideDrawer
            from="calendar"
            setChats={setChats}
            user={user}
            setSelectedChat={setSelectedChat}
            alert={alert}
            setAlert={setAlert}
            privateChats={privateChats}
            setPrivateChats={setPrivateChats}
          />
        </>
      );
    }
  }, [user]);
  useEffect(() => {
    if (modalMode === "newTaskAdded") {
      setTasks([]);
      if (task) {
        getATask();
      } else {
        getTasks();
      }
    }
  }, [fetchAgain]);

  useEffect(() => {
    if (baseDate) {
      if (task) {
        console.log(`tasks`);
        console.log(baseDate);
        console.log(`tasks`);
        setContent(
          <CalendarRows
            rawChat={tasks}
            setSelectedChat={setSelectedChat}
            item={tasks}
            title={title}
            setTitle={setTitle}
            setTaskDate={setTaskDate}
            isOpen={isOpen}
            onOpen={onOpen}
            setCid={setCid}
            onClose={onClose}
          />
        );
      } else {
        setContent(
          tasks.map((item) => (
            <CalendarRows
              rawChat={item}
              setSelectedChat={setSelectedChat}
              item={item}
              title={title}
              setTitle={setTitle}
              setTaskDate={setTaskDate}
              isOpen={isOpen}
              onOpen={onOpen}
              setCid={setCid}
              onClose={onClose}
              setBaseDate={setBaseDate}
              baseDate={baseDate}
            />
          ))
        );
      }
    }
  }, [tasks, fetchAgain]);
  useEffect(() => {
    setBaseDate(new Date(format(selectedDay.min, "yyyy/MM/dd")));
    setEnd(new Date(format(selectedDay.max, "yyyy/MM/dd")));
    setDaysCount(selectedDay.daysCount);
  }, [selectedDay]);
  useEffect(() => {
    if ((chatPanel === true || chatsPanel === true) && notesPanel === true) {
      setSize("full");
    } else if (
      chatPanel === false &&
      chatsPanel === false &&
      notesPanel === false
    ) {
      setSize("sm");
    } else {
      setSize("xl");
    }
  }, [notesPanel, chatPanel, chatsPanel]);
  useEffect(() => {
    if (drawerMode === "notes") {
      setNotesPanel(true);
    } else if (drawerMode === "chat") {
      setChatPanel(true);
      setChatsPanel(false);
    } else if (drawerMode === "chats") {
      setChatPanel(false);
      setChatsPanel(true);
    }
  }, [drawerMode]);

  // console.log(`baseDate2: ${selectedDay.min}, baseDate: ${baseDate}`);
  // console.log(myArray.length);

  const handleRemoveUser = async (userToRemove) => {};
  const handleAddUser = async (userToRemove) => {};
  return (
    <div className="calendarpage">
      <UpdateGroupChatModal />
      <Modal size={"lg"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className="modalheader" alignContent={"center"}>
            {modalMode === "calendarnew" ? (
              <>{format(baseDate, "yyyy-MM-dd")}</>
            ) : (
              title
            )}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {modalMode === "calendarnew" ? (
              <></>
            ) : (
              <VStack>
                <Box padding={"2px"} borderBottom={"1px solid #eaeaea"}></Box>
                <Box
                  bg={"#fafafa"}
                  border={"1px solid #eeeeee"}
                  borderRadius={5}
                  w={"100%"}
                  p={3}
                  alignContent={"center"}
                  alignSelf={"center"}
                  align={"center"}
                  alignItems={"center"}
                >
                  <Box marginBottom={3}>
                    <p className="small">
                      <span className="strong">{taskDate}</span> ustaw jako:
                    </p>
                  </Box>
                  <HStack w={"100%"} padding={"2px 2px"}>
                    <DataSaveComponent
                      cid={cid}
                      newDate={taskDate}
                      target={"from"}
                    />
                    <DataSaveComponent
                      cid={cid}
                      newDate={taskDate}
                      target={"to"}
                    />
                  </HStack>
                  <HStack w={"100%"} padding={"2px 2px"}></HStack>
                </Box>
                {/* <Button
                colorScheme="teal"
                leftIcon={<img width={20} alt="set alarm" src={alarmwhite} />}
                color={"white"}
                w={"100%"}
                bg={"#9fba3c"}
              >
                dodaj przypomnienie
              </Button> */}
                <Box w={"100%"}>
                  <Tabs
                    align="center"
                    alignContent={"center"}
                    alignItems={"center"}
                    alignSelf={"center"}
                  >
                    <TabList>
                      <Tab>Wydarzenia</Tab>
                      <Tab>Alarmy</Tab>
                    </TabList>

                    <TabPanels m={0} p={0}>
                      <TabPanel m={0} p={0}>
                        <EventsComponent
                          cid={cid}
                          user={user}
                          date={taskDate}
                        />
                      </TabPanel>
                      <TabPanel>
                        <AlarmsComponent cid={cid} width={"100%"} />
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </Box>
              </VStack>
            )}
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      <span className="top">{top}</span>
      <div border={"none"}>
        <HStack padding={"10px 10px 0px 10px"}>
          <DatesRange />
          <CalendarTaskSearch />
        </HStack>
        <div m={"0px"} p={"0px"} className="tableContainer">
          <DoubleScrollBar>
            <table
              border={"none"}
              marginBottom={"50px"}
              className="tableCalendar"
            >
              <thead>
                <CalendarTh daysCount={daysCount} />
              </thead>
              <tbody>{content}</tbody>
            </table>
          </DoubleScrollBar>
        </div>
      </div>
      {user && (
        <>
          <Drawer
            size={size}
            isOpen={drawerOpen}
            placement="right"
            onClose={closeChatDrawer}
          >
            <DrawerOverlay />
            <DrawerContent bg={"white"} height={"100%"}>
              <DrawerBody
                margin={0}
                padding={0}
                top={"0px"}
                verticalAlign={"top"}
              >
                <Table height={"100%"} bg={"white"}>
                  <Tbody bg={"white"}>
                    <Tr h={"50px"}>
                      <Td colSpan={2}>
                        <HStack>
                          <VStack
                            padding={"0px 15px 0px 0px"}
                            margin={"0px 15px 0px 0px"}
                            borderRight={"1px solid #efefef"}
                          >
                            <Button
                              bg={"transparent"}
                              onClick={() => {
                                dispatch(setModalMode("edit"));
                                dispatch(setIsUpdateChatModalOpen(true));
                              }}
                            >
                              <FontAwesomeIcon color="#aaaaaa" icon={faGear} />
                            </Button>
                            <Text className="drawerInfo">Edytuj</Text>
                          </VStack>

                          {/* <Button width={"40px"} bg={"transparent"} onClick={handleAlarm} size={"sm"}>
                {selectedTask?.alarm?.length > 0 ? (
                  <img alt="set alarm" src={alarmon} />
                ) : (
                  <img
                    className="alarmimg"
                    width={"15px"}
                    height={"15px"}
                    src={alarm}
                    alt="set alarm"
                  />
                )}
              </Button>
              {doneComponent} */}
                          <p className="gray small closeTitle">
                            {" "}
                            {selectedTask.chatName}
                          </p>
                          <Button
                            className={"closeModal"}
                            bg={"transparent"}
                            onClick={closeChatDrawer}
                            p={3}
                            size={"lg"}
                            color={"#aaaaaa"}
                          >
                            <FontAwesomeIcon
                              className="hand"
                              color="gray"
                              icon={faXmark}
                            />
                          </Button>
                        </HStack>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td textAlign={"center"} className="notesTd">
                        <VStack>
                          <Button bg={notesColor} onClick={toogleNotes}>
                            <FontAwesomeIcon
                              color="white"
                              icon={faPenToSquare}
                            />
                          </Button>
                          <Fade
                            in={notesPanel}
                            transition={{
                              exit: { duration: 0.01 },
                              enter: { delay: 0.2, duration: 2 },
                            }}
                          >
                            <Text className="drawerInfo">Notatki prywatne</Text>
                          </Fade>
                        </VStack>
                      </Td>
                      <Td textAlign={"center"} className="commentTd">
                        <HStack>
                          <VStack w={"100%"}>
                            <Button bg={chatColor} onClick={toogleChat}>
                              <FontAwesomeIcon color="white" icon={faComment} />
                            </Button>
                            <Fade
                              in={chatPanel}
                              transition={{
                                exit: { duration: 0.01 },
                                enter: { delay: 0.2, duration: 2 },
                              }}
                            >
                              <Text className="drawerInfo">
                                Wiadomości <b>prywatne</b>
                              </Text>
                            </Fade>
                          </VStack>
                          <VStack
                            alignContent={"center"}
                            alignItems={"center"}
                            align={"center"}
                            alignSelf={"center"}
                            w={"100%"}
                            textAlign={"center"}
                          >
                            <Button bg={chatsColor} onClick={toogleChats}>
                              <FontAwesomeIcon
                                color="white"
                                icon={faComments}
                              />
                            </Button>
                            <Fade
                              in={chatsPanel}
                              transition={{
                                exit: { duration: 0.01 },
                                enter: { delay: 0.2, duration: 2 },
                              }}
                            >
                              <Text className="drawerInfo">
                                Wiadomości <b>publiczne</b>
                              </Text>
                            </Fade>
                          </VStack>
                        </HStack>
                      </Td>
                    </Tr>
                    <Tr h={"95%"}>
                      <Td
                        minWidth={
                          notesPanel
                            ? chatsPanel || chatPanel
                              ? "50%"
                              : "100%"
                            : "20px"
                        }
                        className="notesTd"
                      >
                        {" "}
                        {notesPanel && (
                          <div className="drawerElement">
                            <NotesBox />
                          </div>
                        )}
                      </Td>
                      <Td
                        minWidth={
                          chatPanel || chatsPanel
                            ? notesPanel
                              ? "50%"
                              : "100%"
                            : "20px"
                        }
                        className="commentTd"
                      >
                        {chatPanel && (
                          <div className="drawerElement">
                            {/* <MyPrivateChats /> */}

                            <SearchUser
                              setter={accessChat}
                              title={"wyszukaj pracownika"}
                            />
                            {loading ? (
                              <ChatLoading />
                            ) : (
                              searchResult?.map((user) => (
                                <UserListItem
                                  key={user._id}
                                  user={user}
                                  handleFunction={() =>
                                    accessChat({
                                      user: user,
                                      chatId: selectedTask?._id,
                                    })
                                  }
                                />
                              ))
                            )}
                            <PrivateChatBox />
                          </div>
                        )}
                        {chatsPanel && (
                          <div className="drawerElement">
                            <ChatBox user={user} />
                          </div>
                        )}
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </>
      )}
    </div>
  );
};

export default Calendarpage;
