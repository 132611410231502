import { ViewIcon } from "@chakra-ui/icons";
import {
  Image,
  IconButton,
  useDisclosure,
  Modal,
  ModalFooter,
  Button,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalOverlay,
  ModalContent,
  HStack,
} from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom";
const ProfileModal = ({ user, children }) => {
  const history = useHistory();
  const goToReset = () => {
    history.push("/reset");
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      {children ? (
        <span onClick={onOpen}>{children}</span>
      ) : (
        <IconButton
          display={{ base: "flex" }}
          icon={<ViewIcon />}
          onClick={onOpen}
        />
      )}
      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{user?.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p>{user?.email}</p>
            <Image
              borderRadius="full"
              boxSize="150px"
              src={user?.pic}
              alt={user?.name}
            ></Image>
          </ModalBody>
          <ModalFooter>
            <HStack w={"100%"} spacing={4}>
              <Button w={"100%"} colorScheme="red" mr={3} onClick={goToReset}>
                Resetuj hasło
              </Button>
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                Zamknij
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ProfileModal;
