import { createSlice } from "@reduxjs/toolkit";

export const selectedAlarmSlice = createSlice({
  name: "selectedAlarm",
  initialState: {
    selectedAlarm: {},
  },

  reducers: {
    setSelectedAlarm: (state, action) => {
      state.selectedAlarm = action.payload;
    },
  },
});

export const { setSelectedAlarm } = selectedAlarmSlice.actions;

export default selectedAlarmSlice.reducer;
