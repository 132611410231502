import { createSlice } from "@reduxjs/toolkit";

export const isGroupChatModalOpenSlice = createSlice({
  name: "isGroupChatModalOpen",
  initialState: {
    isGroupChatModalOpen: false,
  },
  reducers: {
    setIsGroupChatModalOpen: (state, action) => {
      state.isGroupChatModalOpen = action.payload;
    },
  },
});

export const { setIsGroupChatModalOpen } = isGroupChatModalOpenSlice.actions;
export default isGroupChatModalOpenSlice.reducer;
