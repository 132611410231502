import { Button, HStack } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import axios from "axios";
import { mainPath } from "../../config/AxiosConfig";
import { setFetchAgain } from "../slices/fetchAgainSlice";
import { useDispatch, useSelector } from "react-redux";

const AcceptTask = ({ user, chat }) => {
  const [acceptLoading, setAcceptLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const fetchAgain = useSelector((state) => state.fetchAgain.fetchAgain);
  const dispatch = useDispatch();

  const sendEmail = async ({ admin }) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await axios
        .post(
          `${mainPath}/api/email/rejection`,
          {
            admin: admin,
            email: user.email,
            title: chat.chatName,
          },
          config
        )
        .then((res) => {});
      // setChats([data, ...chats]);
      // onClose();
    } catch (error) {
      console.log(error);
    }
  };
  const acceptTask = async ({ accept }) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await axios
        .post(
          `${mainPath}/api/chat/accept/${chat._id}`,
          {
            chatId: chat._id,
            userId: user._id,
            accept: accept,
          },
          config
        )
        .then((res) => {
          if (accept === false) {
            sendEmail({ admin: res.data.groupAdmin[0] });
          }
          setAcceptLoading(false);
          setRejectLoading(false);
          dispatch(setFetchAgain(!fetchAgain));
        });
    } catch (error) {
      // console.log(error);
      setAcceptLoading(false);
      setRejectLoading(false);
    }
  };
  const handleAccept = () => {
    setAcceptLoading(true);
    acceptTask({ accept: true });
  };
  const handleReject = () => {
    setRejectLoading(true);
    acceptTask({ accept: false });
  };
  return (
    <HStack w={"100%"}>
      <Button
        isLoading={acceptLoading}
        w={"auto"}
        onClick={handleAccept}
        color="white"
        size={"sm"}
        bg={"#9fba3c"}
        leftIcon={<FontAwesomeIcon icon={faUser} />}
      >
        Zaakceptuj
      </Button>
      <Button
        isLoading={rejectLoading}
        w={"auto"}
        border={"1px solid #eeeeee"}
        onClick={handleReject}
        marginLeft={"10px"}
        color="#aaaaaa"
        size={"sm"}
        bg={"transparent"}
        leftIcon={<FontAwesomeIcon icon={faXmark} />}
      >
        <p>Odrzuć</p>
      </Button>
    </HStack>
  );
};

export default AcceptTask;
