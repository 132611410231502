import { createSlice } from "@reduxjs/toolkit";

export const selectedFileSlice = createSlice({
  name: "selectedFile",
  initialState: {
    selectedFile: {},
  },

  reducers: {
    setSelectedFile: (state, action) => {
      state.selectedFile = action.payload;
    },
  },
});

export const { setSelectedFile } = selectedFileSlice.actions;

export default selectedFileSlice.reducer;
