import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderOpen } from "@fortawesome/free-solid-svg-icons";
import { Button, HStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mainPath } from "../../config/AxiosConfig";
import axios from "axios";
import { setIsUpdateChatModalOpen } from "../slices/isUpdateChatModalOpenSlice";
import { setIsAttachmentsModalOpen } from "../slices/isAttachmentsModalOpenSlice";
import { setSelectedTask } from "../slices/selectedTaskSlice";
import { setChatFiles } from "../slices/chatFilesSlice";
const ChatAttachments = ({
  rawChat,
  setSelectedChat,
  setSelectedPrivateChat,
}) => {
  const [loading, setLoading] = useState(true);
  const [chat, setChat] = useState({});

  const user = useSelector((state) => state.userInfo.userInfo);
  const fetchChatDetails = async () => {
    setLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await axios
        .post(
          // `${mainPath}/api/chat/sort/${sort}/${asc}`,
          `${mainPath}/api/files/get`,
          {
            uid: user._id,
            cid: rawChat?._id,
          },
          config
        )
        .then((res) => {
          // // // console.log(res);
          setChat(res.data);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchChatDetails();
  }, [rawChat]);
  const dispatch = useDispatch();
  const handleSubClick = () => {
    dispatch(setSelectedTask(rawChat));
    dispatch(setChatFiles(chat.files));
    dispatch(setIsAttachmentsModalOpen(true));
  };
  return (
    <Button
      isDisabled={loading}
      bg={chat?.filesNr > 0 ? "transparent" : "transparent"}
      p={"1px 21px 1px 15px"}
      size={"xs"}
      color="blue.400"
      onClick={() => handleSubClick()}
      leftIcon={
        <FontAwesomeIcon
          color={chat?.filesNr > 0 ? "#fcba03" : "#aaaaaa"}
          icon={faFolderOpen}
        />
      }
    >
      <HStack>
        <span padding={"0px 5px"} className="small lightgray">
          {chat?.filesNr}
        </span>
        {chat?.newFiles > 0 && (
          <text padding={"0px 5px"} className="small red">
            {chat?.filesNr}
          </text>
        )}
      </HStack>
    </Button>
  );
};

export default ChatAttachments;
