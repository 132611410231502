import {
  Box,
  Button,
  Collapse,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { setFetchAgain } from "../slices/fetchAgainSlice";
import { mainPath } from "../../config/AxiosConfig";
const SingleAlarmComponent = ({ alarm, cid, user }) => {
  console.log(alarm);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [alarmExists, setAlarmExists] = useState(false);
  const [hour, setHour] = useState(alarm.hour);
  const [endDate, setEndDate] = useState(
    new Date(`${alarm.year}-${alarm.month}-${alarm.day}`),
  );
  const dispatch = useDispatch();
  const fetchAgain = useSelector((state) => state.fetchAgain.fetchAgain);
  const delAlarm = async () => {
    setDelLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const aid = alarm._id;
      const { data } = await axios
        .post(
          `${mainPath}/api/alarm/del/${aid}`,
          {
            cid: cid,
            uid: user._id,
          },
          config,
        )
        .then((res) => {
          dispatch(setFetchAgain(!fetchAgain));
          setAlarmExists(false);
          setDelLoading(false);
        });
    } catch (error) {
      // console.log(error);
      setLoading(false);
    }
  };
  const setAlarm = async () => {
    setLoading(true);

    let date_time = new Date(endDate);

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      let date_time = new Date(endDate);

      let year = date_time.getFullYear();
      let month = ("0" + (date_time.getMonth() + 1)).slice(-2);
      let day = ("0" + date_time.getDate()).slice(-2);

      const aid = alarm._id;
      const { data } = await axios
        .post(
          `${mainPath}/api/alarm/set/${aid}`,
          {
            cid: cid,
            uid: user._id,
            day: day,
            month: month,
            year: year,
            hour: parseInt(hour),
          },
          config,
        )
        .then((res) => {
          dispatch(setFetchAgain(!fetchAgain));
          setAlarmExists(true);
          setLoading(false);
        });
    } catch (error) {
      // console.log(error);
      setLoading(false);
    }
  };

  return (
    <>
      <Box
        w={"100%"}
        marginBottom={5}
        padding={5}
        bg={"#ffffff"}
        borderRadius={5}
        border={"1px solid #eaeaea"}
      >
        <VStack>
          <HStack w={"100%"}>
            <HStack w={"60%"}>
              <FontAwesomeIcon color="#90cdf4" icon={faCalendarDays} />
              <Box
                w={"100%"}
                h={"40px"}
                alignContent={"center"}
                border={"1px solid #eaeaea"}
                borderRadius={5}
                p={1}
              >
                <DatePicker
                  wrapperClassName="datepicker"
                  alignItems={"center"}
                  isDisabled={isAdmin && true}
                  width="150px"
                  textAlign={"center"}
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                />
              </Box>
            </HStack>
            <HStack w={"40%"} marginLeft={4}>
              <FontAwesomeIcon color="#90cdf4" icon={faClock} />
              <Box
                w={"100%"}
                alignContent={"center"}
                border={"0px solid #eaeaea"}
                borderRadius={5}
                p={1}
              >
                <Select
                  value={hour}
                  onChange={(e) => setHour(e.target.value)}
                  fontSize={"12px"}
                  h={"40px"}
                >
                  <option value={0}>00 : 00</option>
                  <option value={1}>01 : 00</option>
                  <option value={2}>02 : 00</option>
                  <option value={3}>03 : 00</option>
                  <option value={4}>04 : 00</option>
                  <option value={5}>05 : 00</option>
                  <option value={6}>06 : 00</option>
                  <option value={7}>07 : 00</option>
                  <option value={8}>08 : 00</option>
                  <option value={9}>09 : 00</option>
                  <option value={10}>10 : 00</option>
                  <option value={11}>11 : 00</option>
                  <option value={12}>12 : 00</option>
                  <option value={13}>13 : 00</option>
                  <option value={14}>14 : 00</option>
                  <option value={15}>15 : 00</option>
                  <option value={16}>16 : 00</option>
                  <option value={17}>17 : 00</option>
                  <option value={18}>18 : 00</option>
                  <option value={19}>19 : 00</option>
                  <option value={20}>20 : 00</option>
                  <option value={21}>21 : 00</option>
                  <option value={22}>22 : 00</option>
                  <option value={23}>23 : 00</option>
                </Select>
              </Box>
            </HStack>
          </HStack>
          <>
            <HStack w={"100%"}>
              <Box w={"30%"}>
                <Button
                  w={"90%"}
                  isLoading={delLoading}
                  onClick={delAlarm}
                  marginTop={2}
                  marginBottom={4}
                  colorScheme="red"
                >
                  Wyłącz
                </Button>
              </Box>
              <Button
                isLoading={loading}
                colorScheme="linkedin"
                bg={"blue.300"}
                color={"#efefef"}
                marginTop={2}
                marginBottom={4}
                w={alarmExists ? "70%" : "100%"}
                onClick={setAlarm}
              >
                Aktualizuj alarm
              </Button>
            </HStack>
          </>
        </VStack>
      </Box>
    </>
  );
};

export default SingleAlarmComponent;
