import { Box, Tag, Tooltip } from "@chakra-ui/react";
import axios from "axios";
import { mainPath } from "../../config/AxiosConfig";
import { useEffect, useState } from "react";

const SubtasksDatesAlertComponent = ({ cid, user }) => {
  const [alert, setAlert] = useState(false);
  const checkDates = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await axios
        .post(
          `${mainPath}/api/chat/dates/subtask/${cid}`,
          {
            uid: user._id,
          },
          config
        )
        .then((res) => {
          if (res.data.length > 0) {
            setAlert(true);
          }
          //   console.log(`res`);
          //   console.log(res.data);
          //   console.log(`res`);
        });
    } catch (error) {
      //   console.log(error);
    }
  };
  useEffect(() => {
    if (user._id) {
      checkDates();
    }
  }, [user]);
  return (
    <>
      {alert ? (
        <Tooltip label="Kończą się terminy dla niektórych poddziałań">
          <Box
            align={"center"}
            w={"20px"}
            borderRadius={"full"}
            bg={"red.400"}
            color={"white"}
          >
            <b className="strong">!</b>
          </Box>
        </Tooltip>
      ) : (
        <></>
      )}
    </>
  );
};

export default SubtasksDatesAlertComponent;
