import { Avatar, Box, Spinner, Tooltip } from "@chakra-ui/react";
import React, { useEffect } from "react";
import ScrollableFeed from "react-scrollable-feed";
import { useSelector } from "react-redux";
import SingleNote from "./SingleNote";

const ScrollableNote = ({ notes }) => {
  // // console.log(`notes`);
  // // console.log(notes);
  // // console.log(`notes`);
  const user = useSelector((state) => state.userInfo.userInfo);
  let content =
    notes?.status !== "empty" &&
    notes?.map((note) => <SingleNote note={note} user={user} />);
  return (
    <>
      <ScrollableFeed>
        {notes && notes.length > 0 ? (
          <>{content}</>
        ) : (
          <Box p={3}>
            <p className="small gray">Tu nie ma jeszcze notatek...</p>
          </Box>
        )}
      </ScrollableFeed>
    </>
  );
};

export default ScrollableNote;
