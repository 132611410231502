import { effect } from "@chakra-ui/react";
import { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

const Context = createContext();

const ContextProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [alert, setAlert] = useState();
  const [selectedPrivateChat, setSelectedPrivateChat] = useState();
  const [selectedChat, setSelectedChat] = useState();
  const [chats, setChats] = useState([]);
  const [privateChats, setPrivateChats] = useState([]);
  const [notification, setNotification] = useState([]);

  const history = useHistory();
  const location = useLocation();
  const { token } = useParams();

  useEffect(() => {
    const userInfo = JSON.parse(localStorage?.getItem("userInfo"));
    setUser(userInfo);
    setAlert("logout");

    if (
      !userInfo &&
      location.pathname !== `/reset` &&
      !location.pathname.includes("/reset")
    ) {
      history.push("/");
    }
  }, [history]);

  return (
    <Context.Provider
      value={{
        user,
        setUser,
        alert,
        setAlert,
        selectedChat,
        selectedPrivateChat,
        setSelectedChat,
        setSelectedPrivateChat,
        chats,
        setChats,
        privateChats,
        setPrivateChats,
        notification,
        setNotification,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const ContextScope = () => {
  return useContext(Context);
};

export default ContextProvider;
