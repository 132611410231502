import { createSlice } from "@reduxjs/toolkit";

export const isAddTaskModalOpenSlice = createSlice({
  name: "isAddTaskModalOpen",
  initialState: {
    isAddTaskModalOpen: false,
  },
  reducers: {
    setIsAddTaskModalOpen: (state, action) => {
      state.isAddTaskModalOpen = action.payload;
    },
  },
});

export const { setIsAddTaskModalOpen } = isAddTaskModalOpenSlice.actions;
export default isAddTaskModalOpenSlice.reducer;
