import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import { store } from "./app/store";
import ContextProvider from "./Context/ContextProvider";
import { Provider } from "react-redux";
import { Slider, extendTheme } from "@chakra-ui/react";

const root = ReactDOM.createRoot(document.getElementById("root"));

const Drawer = {
  sizes: {
    menu: {
      dialog: { maxWidth: "10px" },
    },
  },
};

const customTheme = extendTheme({
  components: {
    Drawer: {
      parts: ["dialog", "header", "body"],
      variants: {
        primary: {},
        secondary: {
          dialog: {
            maxW: "95vw",
          },
        },
      },
    },
  },
});

root.render(
  <BrowserRouter>
    <ContextProvider>
      <ChakraProvider theme={customTheme}>
        <Provider store={store}>
          <App />
        </Provider>
      </ChakraProvider>
    </ContextProvider>
  </BrowserRouter>
);
