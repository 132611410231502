// import { Button } from '@chakra-ui/react';
import { Route, Switch } from "react-router-dom";
import Chatpage from "../src/Pages/Chatpage";
import Resetpage from "../src/Pages/Resetpage";
import Homepage from "../src/Pages/Homepage";
import Calendarpage from "../src/Pages/Calendarpage";
import "./App.css";
import { Slider } from "@chakra-ui/react";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path="/" component={Homepage} />
        <Route exact path="/calendar/:task?" component={Calendarpage} />
        <Route exact path="/chats/:sort?/:asc?" component={Chatpage} />
        <Route exact path="/reset/:token?" component={Resetpage} />
      </Switch>
    </div>
  );
}

export default App;
