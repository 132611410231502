import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  Input,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import ScrollableFeed from "react-scrollable-feed";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedFile } from "../slices/selectedFileSlice";
import { mainPath } from "../../config/AxiosConfig";

const SendFilesComponent = ({ user }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [file, setFile] = useState();
  const [caption, setCaption] = useState();
  const [popupOpen, setPopupOpen] = useState(false);
  const selectedFile = useSelector((state) => state.selectedFile.selectedFile);
  const fileRef = useRef();
  const handleSubmit = async () => {
    const name = document.getElementById("name");
    const files = document.getElementById("file-upload");
    const formData = new FormData();
    let filename = "";
    if (name.value !== undefined && name.value !== "") {
      // // console.log(`tu`);
      filename = name.value;
    } else {
      // // console.log(`tu2`);
      filename = files.files[0].name;
    }
    // // console.log(`filename`);
    // // console.log(filename);
    // // console.log(`filename`);
    formData.append("name", filename);
    formData.append("file", files.files[0]);
    dispatch(setSelectedFile({ name: filename, file: files.files[0] }));
    setPopupOpen(false);
  };
  const dispatch = useDispatch();
  const clearFile = () => {
    setFile(null);
    setCaption(null);
    dispatch(setSelectedFile({}));
  };
  const handleChange = (e) => {
    e.preventDefault();
    // // // // console.log(e.target);
    setFile(e.target.value);
    // setCaption(e.target.value);
  };
  useEffect(() => {
    // // console.log(selectedFile);
    if (selectedFile?.name) {
    } else {
      setFile("");
      setCaption("");
    }
  }, [selectedFile]);
  // useEffect(() => {
  //   if (file) {
  //     const name = document.getElementById("name");
  //     const files = document.getElementById("file-upload");
  //     const formData = new FormData();
  //     formData.append("name", name.value);
  //     formData.append("file", files.files[0]);
  //     dispatch(setSelectedFile({ fileName: name.value, file: files.files[0] }));
  //   }
  // }, [file]);
  const handleCaption = (e) => {
    setCaption(e.target.value);
  };
  return (
    <Box bg={"white"} p={"4px 5px"} borderRadius={"5px"}>
      <HStack>
        <Popover
          isOpen={popupOpen}
          bg={"#fafafa"}
          placement="bottom"
          closeOnBlur={true}
        >
          <PopoverTrigger>
            <Button
              onClick={() => {
                setPopupOpen(true);
              }}
              bg={"gray.100"}
              size={"xs"}
              color={selectedFile?.fileName ? "blue.300" : "#aaaaaa"}
            >
              <FontAwesomeIcon size="md" icon={faPaperclip} />
            </Button>
          </PopoverTrigger>
          <PopoverContent w={"250px"}>
            <PopoverArrow />
            <PopoverCloseButton
              onClick={() => {
                setPopupOpen(false);
              }}
            />
            <PopoverBody>
              <form id="form">
                <br />
                <Box class="input-group">
                  <label for="name" className="small gray">
                    nazwa pliku:
                  </label>
                  <br />
                  <input
                    value={caption && caption}
                    onChange={handleCaption}
                    name="name"
                    id="name"
                    placeholder="Wpisz nazwę pliku"
                    max-width={"120px"}
                    className="custom-file-name"
                  />
                </Box>
                <Box
                  p={"0px 5px 15px 0px"}
                  w={"100%"}
                  alignContent={"center"}
                  align={"center"}
                  alignItems={"center"}
                  alignSelf={"center"}
                  textAlign={"center"}
                >
                  <label for="file-upload" className="custom-file-upload">
                    <input
                      onChange={handleChange}
                      id="file-upload"
                      type="file"
                    />
                    Wybierz plik
                  </label>
                  {file && (
                    <Button
                      marginLeft={"10px"}
                      size={"sm"}
                      color={"white"}
                      bg={"blue.200"}
                      p={"0px 15px 2px 15px"}
                      onClick={handleSubmit}
                    >
                      Zapisz
                    </Button>
                  )}
                </Box>
              </form>
            </PopoverBody>
          </PopoverContent>
        </Popover>
        {selectedFile?.name && (
          <Button onClick={clearFile} size={"xs"} bg={"transparent"}>
            <FontAwesomeIcon size="md" color="red" icon={faXmark} />
          </Button>
        )}
      </HStack>
    </Box>
  );
};

export default SendFilesComponent;
