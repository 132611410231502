import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setModalMode } from "../slices/modalModeSlice";
import { Thead, useDisclosure } from "@chakra-ui/react";
import { format } from "date-fns";
import GroupChatModal from "../miscellaneous/GroupChatModal";
import { setIsGroupChatModalOpen } from "../slices/isGroupChatModalOpenSlice";

const CalendarTh = ({ selected, setSelected }) => {
  const dispatch = useDispatch();
  const [baseDate, setBaseDate] = useState(new Date());
  const [daysCount, setDaysCount] = useState(3);
  const [myArray, setMyArray] = useState([]);
  const [myMonthsArray, setMyMonthsArray] = useState([]);
  const selectedDay = useSelector((state) => state.selectedDay.selectedDay);
  useEffect(() => {
    setDaysCount(selectedDay.daysCount);
    console.log(`selectedDay`);
    console.log(selectedDay);
    setBaseDate(new Date(format(selectedDay.min, "yyyy/MM/dd")));
  }, [selectedDay]);
  const monthsContent = () => {
    let tds = [];
    myMonths.map((m) => {
      tds.push(
        <th colspan={m.count + 1}>
          <p className="thmonth">{m.month}</p>
        </th>
      );
    });
    return tds;
  };
  const daysContent = () => {
    let tds = [];
    for (let i = 0; i < daysCount; i++) {
      baseDate.setDate(baseDate.getDate() + 1);
      const tddate = format(baseDate, "yyyy-MM-dd").toString();
      tds.push(
        <th
          onClick={() => {
            console.log("zzz");
            dispatch(setModalMode("new"));
            dispatch(setIsGroupChatModalOpen(true));
          }}
          className={"thday"}
          key={tddate}
        >
          <p>{baseDate.getDate()}</p>
        </th>
      );
    }
    return tds;
  };
  useEffect(() => {
    setMyArray(daysContent());
    setMyMonthsArray(monthsContent());
  }, [daysCount]);
  let thdate = new Date(selectedDay.min);
  let myMonths = [];
  let myYears = {};

  const monthName = (month) => {
    switch (month) {
      case 0:
        return "styczeń";
        break;
      case 1:
        return "luty";
        break;
      case 2:
        return "marzec";
        break;
      case 3:
        return "kwiecien";
        break;
      case 4:
        return "maj";
        break;
      case 5:
        return "czerwiec";
        break;
      case 6:
        return "lipiec";
        break;
      case 7:
        return "sierpien";
        break;
      case 8:
        return "wrzesien";
        break;
      case 9:
        return "pazdziernik";
        break;
      case 10:
        return "listopad";
        break;
      case 11:
        return "grudzien";
        break;
      default:
        break;
    }
  };
  for (let i = 0; i < selectedDay.daysCount; i++) {
    thdate.setDate(thdate.getDate() + 1);
    let included = false;
    myMonths = myMonths.map((m) => {
      if (
        m.month === monthName(thdate.getMonth()) &&
        m.year === thdate.getFullYear()
      ) {
        included = true;
        return { ...m, count: m.count + 1 };
      } else return { ...m };
    });
    if (included === false) {
      myMonths.push({
        year: thdate.getFullYear(),
        month: monthName(thdate.getMonth()),
        count: 0,
      });
    }
  }
  console.log(myMonths);
  return (
    <>
      <GroupChatModal />
      <tr>
        <td></td>
        {myMonthsArray.map((item) => item)}
      </tr>
      <tr>
        <td></td>
        {myArray.map((item) => item)}
      </tr>
    </>
  );
};

export default CalendarTh;
