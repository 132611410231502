import { Box, Stack, Text } from "@chakra-ui/layout";
import { Table, Tbody, Th, Thead, useToast } from "@chakra-ui/react";
import { Button } from "@chakra-ui/button";
import axios from "axios";
import { mainPath } from "../config/AxiosConfig";
import React, { useEffect, useState } from "react";
import { ContextScope } from "../Context/ContextProvider";
import { AddIcon } from "@chakra-ui/icons";
import ChatLoading from "./ChatLoading";
import GroupChatModal from "./miscellaneous/GroupChatModal";
import TaskComponent from "./task/TaskComponent";
import PrivateComponent from "./private/PrivateComponent";
import { useSelector } from "react-redux";

const MyPrivateChats = () => {
  const [loggedUser, setLoggedUser] = useState();
  const {
    user,
    alert,
    setAlert,
    selectedChat,
    setSelectedChat,
    privateChats,
    setPrivateChats,
  } = ContextScope();
  const user2 = JSON.parse(localStorage.getItem("userInfo"));
  const toast = useToast();
  const selectedTask = useSelector((state) => state.selectedTask.selectedTask);

  const fetchAgain = useSelector((state) => state.fetchAgain.fetchAgain);
  const fetchChats = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user2.token}`,
        },
      };
      const { data } = await axios.get(
        `${mainPath}/api/chat/private/${selectedTask._id}`,
        config
      );
      setPrivateChats(data);
      console.log(data);
    } catch (error) {
      // toast({
      //   title: error.message,
      //   status: "error",
      //   duration: 5000,
      //   isClosable: true,
      //   position: "bottom-left",
      // });
    }
  };

  useEffect(() => {
    setLoggedUser(JSON.parse(localStorage.getItem("userInfo")));
    fetchChats();
  }, [fetchAgain]);
  return (
    <Box
      borderRadius={"10px"}
      border={"1px solid #efefef"}
      h={"70%"}
      marginTop={"50px"}
      key={"private-chat-table"}
    >
      <Table verticalAlign={"top"} w="100%">
        <Tbody verticalAlign={"top"} h={"100%"} w="100%">
          {privateChats ? (
            <>
              {privateChats.map((chat) => (
                <PrivateComponent
                  w="100%"
                  backgroundColor={"blue"}
                  chat={chat}
                  key={chat._id}
                  loggedUser={loggedUser}
                />
              ))}{" "}
            </>
          ) : (
            <ChatLoading />
          )}
        </Tbody>
      </Table>
    </Box>
  );
};

export default MyPrivateChats;
