import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  HStack,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { setIsAttachmentsModalOpen } from "../slices/isAttachmentsModalOpenSlice";
import AttachmentsTable from "./AttachmentsTable";
import { useEffect, useState } from "react";
import SendFilesComponent from "./SendFilesComponent";
import axios from "axios";
import { mainPath } from "../../config/AxiosConfig";
import { setFetchAgain } from "../slices/fetchAgainSlice";

const AttachmentsModal = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const fetchAgain = useSelector((state) => state.fetchAgain.fetchAgain);
  const selectedFile = useSelector((state) => state.selectedFile.selectedFile);
  const selectedTask = useSelector((state) => state.selectedTask.selectedTask);
  const dispatch = useDispatch();
  const [content, setContent] = useState(<></>);
  const chatFiles = useSelector((state) => state.chatFiles.chatFiles);
  const isAttachmentsModalOpen = useSelector(
    (state) => state.isAttachmentsModalOpen.isAttachmentsModalOpen
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(() => {
    if (chatFiles.length > 0) {
      // // // // // // console.log(chatFiles);
      setContent(<AttachmentsTable user={user} files={chatFiles} />);
    }
  }, [chatFiles]);

  const submitImage = async () => {
    setLoading(true);
    if (selectedFile?.file.name) {
      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${user.token}`,
          },
        };
        const formData = new FormData();
        formData.append("cid", selectedTask._id);
        formData.append("uid", user._id);
        formData.append("file", selectedFile.file);
        formData.append("name", selectedFile.name);
        const { data } = await axios
          .post(`${mainPath}/api/file/upload`, formData, config)
          .then((res) => {
            if (res.data.message === "success") {
              dispatch(setFetchAgain(!fetchAgain));
              // // // // // console.log(res.data);
              setLoading(false);
            }
          });
      } catch (error) {}

      //==
    } else {
    }
  };
  useEffect(() => {
    // // // // // console.log(`selectedFile`);
    // // // // // console.log(selectedFile);
    // // // // // console.log(`selectedFile`);
    if (selectedFile) {
    }
  }, [selectedFile]);
  return (
    <>
      <Modal
        isOpen={isAttachmentsModalOpen}
        onClose={() => {
          dispatch(setIsAttachmentsModalOpen(false));
        }}
      >
        <ModalOverlay />
        <ModalContent minW="60%" maxW="90%">
          <ModalHeader>
            Pliki przypisane do zadania: {chatFiles?.length}
          </ModalHeader>
          <ModalCloseButton
            onClick={() => {
              dispatch(setIsAttachmentsModalOpen(false));
            }}
          />
          <ModalBody>
            <>
              <HStack>
                <SendFilesComponent user={user} />
                <Button
                  isDisabled={selectedFile?.file ? false : true}
                  bg={"blue.200"}
                  marginLeft={1}
                  size={"xs"}
                  isLoading={loading}
                  onClick={submitImage}
                  color={"white"}
                  rightIcon={
                    <FontAwesomeIcon
                      className="hand"
                      color="white"
                      icon={faFile}
                    />
                  }
                >
                  Dodaj plik
                </Button>
              </HStack>
              {content}
            </>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => {
                dispatch(setIsAttachmentsModalOpen(false));
              }}
            >
              Zamknij
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AttachmentsModal;
