import {
  Box,
  Button,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Select,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { mainPath } from "../../config/AxiosConfig";
import { setFetchAgain } from "../slices/fetchAgainSlice";
import { setSelectedTask } from "../slices/selectedTaskSlice";

const AddEvent = ({ cid, date, month, hour }) => {
  const user = useSelector((state) => state.userInfo.userInfo);
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [minute, setMinute] = useState(0);
  const fetchAgain = useSelector((state) => state.fetchAgain.fetchAgain);
  const dispatch = useDispatch();
  const handleDesc = (e) => {
    setDescription(e.target.value);
  };
  const addHandler = async () => {
    setLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await axios
        .post(
          `${mainPath}/api/event/set`,
          {
            cid: cid,
            uid: user._id,
            date: date,
            hour: parseInt(hour),
            minute: minute,
            description: description,
          },
          config
        )
        .then((res) => {
          dispatch(setSelectedTask(cid));
          dispatch(setFetchAgain(!fetchAgain));
          setLoading(false);
        });
    } catch (error) {}
  };
  const handleMinute = (e) => {
    setMinute(parseInt(e.target.value));
  };
  return (
    <Box
      marginTop={3}
      bg={"#fafafa"}
      border={"2px solid"}
      borderColor={"blue.100"}
      borderRadius={8}
      p={3}
    >
      Opis:
      <InputGroup>
        <Input
          onChange={handleDesc}
          bg={"white"}
          size={"md"}
          placeholder="opis"
        />
      </InputGroup>
      <HStack marginTop={"15px"}>
        <p>Minuta: </p>
        <InputGroup>
          <Select onChange={handleMinute} bg={"white"} size={"md"} w={"100px"}>
            <option value={0}>{hour}:00</option>
            <option value={10}>{hour}:10</option>
            <option value={20}>{hour}:20</option>
            <option value={30}>{hour}:30</option>
            <option value={40}>{hour}:40</option>
            <option value={50}>{hour}:50</option>
          </Select>
        </InputGroup>
        <Button
          isLoading={loading}
          onClick={addHandler}
          p={"5px 20px"}
          color={"white"}
          bg={"blue.200"}
          size={"sm"}
        >
          Zapisz
        </Button>
      </HStack>
    </Box>
  );
};

export default AddEvent;
