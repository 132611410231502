import { createSlice } from "@reduxjs/toolkit";

export const isUpdateChatModalOpenSlice = createSlice({
  name: "isUpdateChatModalOpen",
  initialState: {
    isUpdateChatModalOpen: false,
  },
  reducers: {
    setIsUpdateChatModalOpen: (state, action) => {
      state.isUpdateChatModalOpen = action.payload;
    },
  },
});

export const { setIsUpdateChatModalOpen } = isUpdateChatModalOpenSlice.actions;
export default isUpdateChatModalOpenSlice.reducer;
