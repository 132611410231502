import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { mainPath } from "../../config/AxiosConfig";
import { useEffect, useState } from "react";
import { Box, Button, Drawer, HStack, Text, VStack } from "@chakra-ui/react";
import { setSelectedTask } from "../slices/selectedTaskSlice";
import { ContextScope } from "../../Context/ContextProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setModalMode } from "../slices/modalModeSlice";
import DrawerSubtask from "./DrawerSubtask";
import ChatLoading from "../ChatLoading";
import { setIsGroupChatModalOpen } from "../slices/isGroupChatModalOpenSlice";
import { faGear, faPlus } from "@fortawesome/free-solid-svg-icons";
import { setSelectedAlarm } from "../slices/selectedAlarmSlice";
import { setIsAlarmModalOpen } from "../slices/isAlarmModalOpenSlice";
import { setIsEventModalOpen } from "../slices/isEventModalOpenSlice";

const SubtasksDrawerComponent = () => {
  const dispatch = useDispatch();
  const [subtasks, setSubtasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadedSubtasks, setLoadedSubtasks] = useState({ status: 0 });
  const [parent, setParent] = useState({});
  const [parentBox, setParentBox] = useState(<></>);
  const [content, setContent] = useState(<ChatLoading />);
  const [newSub, setNewSub] = useState(false);
  const user = useSelector((state) => state.userInfo.userInfo);
  const fetchAgain = useSelector((state) => state.fetchAgain.fetchAgain);
  const { selectedChat, setSelectedChat } = ContextScope();
  const selectedTask = useSelector((state) => state.selectedTask.selectedTask);
  const getSubtasks = async () => {
    setLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      };
      const { data } = await axios
        .post(
          `${mainPath}/api/chat/subtasks/${parent?._id}`,
          {
            chatId: parent?._id,
            userId: user?._id,
          },
          config
        )
        .then((res) => {
          // console.log(res);
          if (res.data?.message !== "404") setSubtasks(res.data);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (subtasks?.length > 0)
      setContent(subtasks?.map((s) => <DrawerSubtask s={s} />));
    else
      setContent(
        <Box p={3}>
          <p className="small gray">To zadanie nie ma jeszcze etapów...</p>
        </Box>
      );

    if (newSub) setSelectedChat(subtasks[0]);

    setNewSub(false);
  }, [subtasks]);
  // useEffect(() => {
  //   if (isGroupChatModalOpen === false) {
  //     if (!selectedChat?._id) setSelectedChat(parent);
  //   }
  // }, [isGroupChatModalOpen]);
  useEffect(() => {
    setContent(
      <Box p={3}>
        <ChatLoading />
      </Box>
    );
    setParent(selectedTask);
    setParentBox(<DrawerSubtask s={selectedTask} />);
    // getSubtasks();
  }, []);
  useEffect(() => {
    getSubtasks();
  }, [parent]);
  useEffect(() => {
    if (parent?._id) {
      getSubtasks();

      if (selectedTask?._id === parent?._id) {
        setParentBox(<DrawerSubtask s={selectedTask} />);
      }
    }
  }, [fetchAgain]);
  const setMode = () => {
    setNewSub(true);
    // // // // console.log("zzz");
    dispatch(setIsGroupChatModalOpen(true));
    dispatch(setSelectedTask(parent));
    setSelectedChat(parent);
    dispatch(setModalMode("sub"));
  };
  const handleAlarm = () => {
    dispatch(setIsAlarmModalOpen(true));
    dispatch(setSelectedAlarm(parent.alarm));
  };
  const handleEvent = () => {
    dispatch(setIsEventModalOpen(true));
    // dispatch(setSelectedAlarm(chat.alarm));
  };
  return (
    <VStack w={"100%"}>
      <Box p={2} w={"100%"} align="left">
        <p className="small gray">Zadanie: </p>
      </Box>
      {parentBox}
      <Box marginTop={"10px"}></Box>
      <Box p={2} w={"100%"} align="left" borderTop={"3px solid #eaeaea"}>
        <p className="small gray">Etapy: </p>
        <Button
          onClick={() => {
            setMode();
          }}
          margin={"6px 0px 0px 4px"}
          alignSelf={"left"}
          align={"left"}
          size={"xs"}
          bg={"blue.200"}
          color={"white"}
        >
          <FontAwesomeIcon size={"md"} icon={faPlus} />
        </Button>
      </Box>
      <Box
        w={"95%"}
        p={0}
        verticalAlign={"top"}
        minWidth={"300px"}
        maxH={"50vh"}
        overflowY={"auto"}
        overflowX={"hidden"}
      >
        {content}
      </Box>
    </VStack>
  );
};

export default SubtasksDrawerComponent;
