import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Slider,
  Spinner,
  VStack,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { mainPath } from "../../config/AxiosConfig";
import { steps } from "framer-motion";
import NewPasswordComponent from "./NewPasswordComponent";

const { useState, useEffect } = require("react");

const Reset = () => {
  const history = useHistory();
  const { token } = useParams();
  const [email, setEmail] = useState("");
  const [content, setContent] = useState(<Spinner />);
  const [loading, setLoading] = useState(false);

  const emailChangeHandler = (e) => {
    setEmail(e.target.value);
  };
  const toast = useToast();
  const handleReset = async () => {
    setLoading(true);
    try {
      const { data } = await axios.post(`${mainPath}/api/reset/email`, {
        email: email,
      });
      setLoading(false);
      toast({
        title: data.message,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
      setContent(data.message);
    } catch (error) {
      setLoading(false);
      setContent(error.response.data.message);
      console.log(error.response);
      toast({
        title: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
    }
  };
  const goBack = () => {
    console.log("wroc");
    history.push("/reset");
  };
  const checkToken = async () => {
    setLoading(true);
    try {
      const { data } = await axios.post(`${mainPath}/api/reset/checktoken`, {
        token: token,
      });
      setLoading(false);
      console.log(data);
      toast({
        title: data.message,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
      setContent(<NewPasswordComponent email={data.message} />);
    } catch (error) {
      setLoading(false);
      if (error?.response?.data.message === "jwt expired") {
        setContent(
          <VStack>
            <p>Link nie jest już aktywny</p>
            <Button onClick={goBack}>wróć</Button>
          </VStack>,
        );
      } else {
        setContent(error?.response?.data.message);
      }
      console.log(error?.response);
      toast({
        title: error?.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
    }
  };
  useEffect(() => {
    if (token) {
      checkToken();
      setContent(<Slider />);
    } else {
      setContent(
        <div>
          <FormControl>
            <FormLabel>Resetuj hasło: </FormLabel>
            <Input
              onChange={emailChangeHandler}
              type="email"
              placeholder="wprowadź email"
            />
            <Button
              isLoading={loading}
              marginTop="10px"
              colorScheme="blue"
              onClick={handleReset}
            >
              Resetuj
            </Button>
          </FormControl>
        </div>,
      );
    }
  }, [token, email]);
  return content;
};

export default Reset;
