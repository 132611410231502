import { createSlice } from "@reduxjs/toolkit";

export const isAlarmModalOpenSlice = createSlice({
  name: "isAlarmModalOpen",
  initialState: {
    isAlarmModalOpen: false,
  },
  reducers: {
    setIsAlarmModalOpen: (state, action) => {
      state.isAlarmModalOpen = action.payload;
    },
  },
});

export const { setIsAlarmModalOpen } = isAlarmModalOpenSlice.actions;
export default isAlarmModalOpenSlice.reducer;
